import {
	OPEN_MODAL_TUTORIAL,
	CLOSE_MODAL_TUTORIAL,
} from "Store/constants";

export const initialState = {
	isOpen : false,
};

const modalTutorial = (state = initialState, action) => {
	switch (action.type) {
		case OPEN_MODAL_TUTORIAL:
			return {
				isOpen : true,
			};
		case CLOSE_MODAL_TUTORIAL:
			return {
				isOpen : false,
			};
		default:
			return state;
	}
};

export default modalTutorial;
