import {
	OPEN_MODAL_ACCESSORIES,
	CLOSE_MODAL_ACCESSORIES,
} from "Store/constants";

// Import Own Components

export const initialState = {
	isOpen          : false,
	accesorieToEdit : {},
};

const modalAccessoriesReducer = (state = initialState, action) => {
	switch (action.type) {
		case OPEN_MODAL_ACCESSORIES:
			return {
				...initialState,
				isOpen : true,
				...action.payload,
			};
		case CLOSE_MODAL_ACCESSORIES:
			return {
				...initialState,
			};
		default:
			return state;
	}
};

export default modalAccessoriesReducer;
