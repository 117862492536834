import {
	GET_CATEGORIES,
	ADD_CATEGORIES,
	GET_ALL_CATEGORIES,
	CLEAR_CATEGORIES_DATA,
} from "Store/constants";

const initialState = {
	categories : [],
	category   : {},
	page       : 1,
	totalPages : 1,
};

const categoriesReducer = (state = initialState, action) => {
	switch (action.type) {
		case GET_CATEGORIES:
			return {
				...state,
				category : action.payload.data,
			};
		case GET_ALL_CATEGORIES:
			return {
				...state,
				categories : [...action.payload.data],
				page       : action.payload.page,
				totalPages : action.payload.totalPages,
			};
		case ADD_CATEGORIES: {
			const parent = action.payload.data?.parent?._id;

			const indexParent = state.categories.findIndex(category => category._id  === parent);

			const categories = [...state.categories];

			if (indexParent !== -1) {
				const child = {
					_id  : action.payload.data._id,
					name : action.payload.data.name,
				};

				const childrensCopy = categories[indexParent]?.childrens ? categories[indexParent].childrens : [];

				categories[indexParent] = {
					...categories[indexParent],
					childrens : [
						...childrensCopy,
						{...child},
					],
				};
			}

			categories.push(action.payload.data);
			return {
				...state,
				categories,
			};
		}
		case CLEAR_CATEGORIES_DATA: {
			return initialState;
		}
		default:
			return state;
	}
};

export default categoriesReducer;
