import {
	createTheme,
	responsiveFontSizes,
} from "@material-ui/core/styles";

import { esES } from "@material-ui/core/locale";

export const ownTheme = {
	0 : {
		primary        : "#3554D1",
		light          : "rgba(53, 84, 209, 0.12)",
		dark           : "#1F327D",
		shadow         : "0 8px 25px -8px #3554D1",
		lightShadow    : "0 8px 25px -15px #3554D1",
		linearGradient : "linear-gradient(45deg, #3554D1, #8598E3)",
	},
	1 : {
		primary        : "#121F3E",
		light          : "rgba(18, 31, 62, 0.12)",
		dark           : "#0A1225",
		shadow         : "0 8px 25px -8px #121F3E",
		lightShadow    : "0 8px 25px -15px #121F3E",
		linearGradient : "linear-gradient(45deg, #121F3E, #70788B)",
	},
	2 : {
		primary        : "#4caf50",
		light          : "rgba(76, 175, 80, 0.12)",
		dark           : "#2D6930",
		shadow         : "0 8px 25px -8px #4caf50",
		linearGradient : "linear-gradient(45deg, #4caf50, #93CF96)",
	},
	3 : {
		primary        : "#f44336",
		light          : "rgba(244, 67, 54, 0.12)",
		dark           : "#922820",
		shadow         : "0 8px 25px -8px #f44336",
		lightShadow    : "0 8px 25px -15px #f44336",
		linearGradient : "linear-gradient(45deg, #f44336, #F88E86)",
	},
	4 : {
		primary        : "#ff7043",
		light          : "rgba(255, 112, 67, 0.12)",
		dark           : "#994328",
		shadow         : "0 8px 25px -8px #ff7043",
		lightShadow    : "0 8px 25px -15px #ff7043",
		linearGradient : "linear-gradient(45deg, #ff7043, #FFA98E)",
	},
	5 : {
		primary        : "#9c27b0",
		light          : "rgba(156, 39, 176, 0.12)",
		dark           : "#5D1769",
		shadow         : "0 8px 25px -8px #9c27b0",
		lightShadow    : "0 8px 25px -15px #9c27b0",
		linearGradient : "linear-gradient(45deg, #9c27b0, #C37DCF)",
	},
	6 : {
		primary        : "#ec407a",
		light          : "rgba(236, 64, 122, 0.12)",
		dark           : "#8D2649",
		shadow         : "0 8px 25px -8px #ec407a",
		lightShadow    : "0 8px 25px -15px #ec407a",
		linearGradient : "linear-gradient(45deg, #ec407a, #F38CAF)",
	},
	7 : {
		primary        : "#009688",
		light          : "rgba(0, 150, 136, 0.12)",
		dark           : "#005A51",
		shadow         : "0 8px 25px -8px #009688",
		lightShadow    : "0 8px 25px -15px #009688",
		linearGradient : "linear-gradient(45deg, #009688, #66C0B7)",
	},
};

// Create a theme instance.
const theme = createTheme({
	palette : {
		primary : {
			main : "#3554D1",
		},
	},
	typography : {
		fontFamily : [
			"Montserrat",
			"-apple-system",
			"'Nunito Sans'",
			"Gotham",
			"BlinkMacSystemFont",
			"'Segoe UI'",
			"Roboto",
			"Oxygen",
			"Ubuntu",
			"Cantarell",
			"'Fira Sans'",
			"'Droid Sans'",
			"'Helvetica Neue'",
			"'Open Sans'",
		].join(","),
	},
}, esES);

export default responsiveFontSizes(theme);
