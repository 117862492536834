import {
	Check,
	XOctagon,
	AlertTriangle,
} from "react-feather";


import customToast from "Helpers/customToast.jsx";


const dispatchToaster = (type = "", status = 0, item = "", lenguage = "") => {
	const len = lenguage ? "en" : "es";

	switch (type) {
		case "ADD": {
			if (len === "en") {
				handleStatusEn(status, "added", item);
			} else {
				handleStatusEs(status, "agregó", item);
			}

			break;
		}
		case "UPDATE": {
			if (len === "en") {
				handleStatusEn(status, "edited", item);
			} else {
				handleStatusEs(status, "editó", item);
			}
			break;
		}
		case "REMOVE": {
			if (len === "en") {
				handleStatusEn(status, "deleted", item, true);
			} else {
				handleStatusEs(status, "eliminó", item, true);
			}
			break;
		}
		default: {
			if (len === "en") {
				handleStatusEn(status, "", item);
			} else {
				handleStatusEs(status, "", item);
			}
			break;
		}
	}
};

const handleStatusEs = (status, action, item, flag = false) => {
	switch (status) {
		case 200: {
			if (flag) {
				customToast.danger({
					icon       : <XOctagon />,
					dataDetail : "",
					tittle     : "¡La operación se realizo con éxito!",
					firstBody  : `${item} se ${action} de`,
					secondBody : "tu lista.",
				});
			} else {
				customToast.primary({
					icon       : <Check />,
					dataDetail : "",
					tittle     : "¡La operación se realizo con éxito!",
					firstBody  : `${item} se ${action}`,
					secondBody : "correctamente.",
				});
			}
			break;
		}
		case 403: {
			customToast.danger({
				icon       : <XOctagon />,
				dataDetail : "",
				tittle     : "¡Permisos insuficientes!",
				firstBody  : "El usuario no tiene los",
				secondBody : "permisos suficientes para realizar esta operación.",
			});
			break;
		}
		case 404: {
			customToast.WarningToast({
				icon       : <AlertTriangle />,
				dataDetail : "",
				tittle     : "Inexistente",
				firstBody  : `Su ${item}`,
				secondBody : "no está dado de alta.",
			});
			break;
		}
		case 406: {
			if (flag) {
				customToast.WarningToast({
					icon       : <XOctagon />,
					dataDetail : "",
					tittle     : `${item} está en uso`,
					firstBody  : `${item} no puede ser eliminado ya`,
					secondBody : "que esta relacionado con otra entidad.",
				});
			} else {
				customToast.WarningToast({
					icon       : <AlertTriangle />,
					dataDetail : "",
					tittle     : "¡Hubo un conflicto al realizar la operación!",
					firstBody  : "No se puedo realizar la operación.",
					secondBody : "Favor de consultar que la operación que esta intentando realizar sea permitida.",
				});
			}
			break;
		}
		case 500: {
			customToast.danger({
				icon       : <XOctagon />,
				dataDetail : "",
				tittle     : "¡Algo salió mal!",
				firstBody  : "Comuníquese con el ",
				secondBody : "el encargado para informar sobre este error.",
			});
			break;
		}
		default: {
			customToast.danger({
				icon       : <XOctagon />,
				dataDetail : status,
				tittle     : "¡Algo salió mal!",
				firstBody  : "Comuníquese con el ",
				secondBody : "el encargado para informar sobre este error.",
			});
		}
	}
};

const handleStatusEn = (status, action, item, flag = false) => {
	switch (status) {
		case 200: {
			if (flag) {
				customToast.danger({
					icon       : <XOctagon />,
					dataDetail : "",
					tittle     : "The task has been successfully done!",
					firstBody  : `${item} was ${action} of`,
					secondBody : "your list.",
				});
			} else {
				customToast.primary({
					icon       : <Check />,
					dataDetail : "",
					tittle     : "The task has been successfully done!",
					firstBody  : `${item} was ${action}`,
					secondBody : "successfully.",
				});
			}
			break;
		}
		case 403: {
			customToast.danger({
				icon       : <XOctagon />,
				dataDetail : "",
				tittle     : "Forbidden!",
				firstBody  : "The user dose not have permission to",
				secondBody : "do this task.",
			});
			break;
		}
		case 404: {
			customToast.WarningToast({
				icon       : <AlertTriangle />,
				dataDetail : "",
				tittle     : "Not Found!",
				firstBody  : "The resource requested",
				secondBody : "could not be found.",
			});
			break;
		}
		case 406: {
			customToast.WarningToast({
				icon       : <AlertTriangle />,
				dataDetail : "",
				tittle     : "Not Acceptable!",
				firstBody  : "This request is not acceptable.",
				secondBody : "",
			});

			break;
		}
		case 500: {
			customToast.danger({
				icon       : <XOctagon />,
				dataDetail : status,
				tittle     : "Oops, something went wrong!",
				firstBody  : "Please try to refresh this page.",
				secondBody : "",
			});
			break;
		}
		default: {
			customToast.danger({
				icon       : <XOctagon />,
				dataDetail : status,
				tittle     : "Oops, something went wrong!",
				firstBody  : "Please try to refresh this page.",
				secondBody : "",
			});
		}
	}
};
export default dispatchToaster;
