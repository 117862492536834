import { connect } from "react-redux";
import {
	Form,
	Label,
	FormGroup,
} from "reactstrap";

//Our Importantios
import { bindAll }      from "Helpers";
import DropZoneImg      from "Components/DropZoneImg";
import FormGroupInput   from "Components/FormGroupInput";
import AsyncSelectGroup from "Components/AsyncSelectGroup";
import SidebarActions   from "Store/Actions/SidebarActions";
import SelectOptions    from "Components/CustomSelectOptions/CategorySelectOptions";
import SaveButton       from "../SaveButton";
import SideBarHeader    from "../SideBarHeader";


const ProductSidebar = ({
	editing,
	sidebarActions,
	delegations : {
		prices,
		errors,
		loading,
		register,
		getValues,
		prodcutImg,
		submitForm,
		handleChange,
		costsSoldier,
		costsRecorded,
		costsPulished,
		setProdcutImg,
		handleSearchCategory,
	},
}) => {
	return (
		<>
			<SideBarHeader
				editing={editing}
				sideBarName="Producto"
				sidebarActions={sidebarActions}
			/>

			<Form onSubmit={submitForm}>

				<FormGroup>
					<Label className="form-label">Añadir Imagen de producto</Label>
				</FormGroup>

				<DropZoneImg
					image={prodcutImg}
					setImage={setProdcutImg}
				/>

				<FormGroupInput
					name="name"
					label="Nombre(s) (Campo único)"
					errors={errors}
					register={register}
					placeholder="Ingresa el nombre"
					feedBack = "Debes ingresar el nombre del producto."
				/>

				<AsyncSelectGroup
					name="category"
					components={{ Option : SelectOptions}}
					errors={errors}
					label="Categoría"
					placeholder="Selecciona"
					value={getValues("category")}
					handleSearch={handleSearchCategory}
					handleChange={(e) => handleChange(e, "category")}
				/>

				<FormGroupInput
					max="10"
					min="0"
					type="number"
					name="no_lines"
					errors={errors}
					register={register}
					options={{  required : true}}
					label="No. De Líneas"
					placeholder="Ingresa el nombre"
					feedBack = "Este campo no puede estar vacío"
				/>

				<FormGroupInput
					name="sku"
					label="Código de Producto"
					errors={errors}
					register={register}
					placeholder="Ingresa el código de producto"
					feedBack = "Debes ingresar un código de producto."
				/>
				<div className="multiple-inpts-group">
					{
						prices.map( list => (
							<FormGroupInput
								min="1"
								type="number"
								key={list.id_price_list}
								name={list.id_price_list}
								errors={errors}
								label={list.name}
								register={register}
								placeholder={`${list.name}`}
							/>
						))
					}
				</div>
				<h5 className="form-label" style={{alignSelf : "baseline"}}>
					Soldado
				</h5>
				<div className="multiple-inpts-group">
					{
						costsSoldier.map( list => {
							return (
								<FormGroupInput
									min="1"
									type="number"
									errors={errors}
									key={list.id_cost_list}
									name={list.id_cost_list}
									label={list.name}
									register={register}
									placeholder={`${list.name}`}
								/>
							);})
					}
				</div>
				<h5 className="form-label" style={{alignSelf : "baseline"}}>
					Grabado
				</h5>
				<div className="multiple-inpts-group">
					{
						costsRecorded.map( list => {
							return (
								<FormGroupInput
									min="1"
									type="number"
									key={list.id_cost_list}
									name={list.id_cost_list}
									errors={errors}
									label={list.name}
									register={register}
									placeholder={`${list.name}`}
								/>
							);})
					}
				</div>
				<h5 style={{alignSelf : "baseline"}}>
					Pulido
				</h5>
				<div className="multiple-inpts-group">
					{
						costsPulished.map( list => {
							return (
								<FormGroupInput
									min="1"
									type="number"
									key={list.id_cost_list}
									name={list.id_cost_list}
									errors={errors}
									label={list.name}
									register={register}
									placeholder={`${list.name}`}
								/>
							);})
					}
				</div>

				<SaveButton text="Producto" loading={loading} />
			</Form>
		</>
	);
};
const mapDispatchToProps = bindAll({ SidebarActions });

export default connect(null, mapDispatchToProps)(ProductSidebar);
