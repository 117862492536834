import { useEffect, useMemo, useState } from "react";
import { useForm }                      from "react-hook-form";

//Our Importations
import { bindAll, ReactSelectSearcher } from "Helpers";
import { connect }                      from "react-redux";
import { useDebounce }                  from "Helpers/Hooks";
import ProductSidebar                   from "./ProductSidebar";
import SidebarActions                   from "Store/Actions/SidebarActions";
import GenericService                   from "Store/Services/GenericService";
import SearcherService                  from "Store/Services/SearcherService";

const defaultInputsFiles = {
	sku      : true,
	name     : true,
	no_lines : true,
	category : true,
};

const ProductSidebarContainer = ({
	open,
	data,
	costs,
	productToEdit,
	sidebarActions,
	genericService,
	searcherService,
	...rest
}) => {
	const editing = useMemo(() => Boolean(productToEdit), [productToEdit]);

	const [prices, setPrices]         = useState([]);
	const [loading, setLoading]       = useState(false);
	const [prodcutImg, setProdcutImg] = useState(productToEdit?.image ?? null);

	const [costsSoldier, setCostsSoldier]   = useState([]);
	const [costsRecorded, setCostsRecorded] = useState([]);
	const [costsPulished, setCostsPulished] = useState([]);

	const { register, handleSubmit, formState : { errors }, getValues, setValue, clearErrors, setError } = useForm({
		defaultValues : {
			sku      : productToEdit?.sku      ?? "",
			name     : productToEdit?.name     ?? "",
			no_lines : productToEdit?.no_lines ?? "",
			category : productToEdit?.category ?
				{
					label : productToEdit.category.name,
					value : productToEdit.category?._id,
				} : null,
		},
	});

	const handleSearchCategory = useDebounce(ReactSelectSearcher.containerFn(searcherService.getCategories), 700, [searcherService]);

	const handleChange = (values, name) => {
		clearErrors(name);
		setValue(name, values);
	};

	const checkPricesList = () => {
		if (editing && productToEdit?.prices_list?.length > 0) {
			const listPrices = productToEdit?.prices_list ? [...productToEdit.prices_list] : [];

			setPricesValues(listPrices);

			data.forEach((price) => {
				const key = price.id_price_list;
				if (!getValues(key)) {
					listPrices.push(price);
				}
			});


			listPrices.sort(sortList);
			setPrices(listPrices);
		} else {
			setPrices(data);
		}
	};

	const sortList = (a, b) => {

		if (!a?.name && !b?.name) return;

		if ( a.name < b.name) {
			return -1;
		}
		return 1;
	};

	const setPricesValues = (prices) => prices.forEach( ({id_price_list, price}) => setValue(id_price_list, price));

	const submitForm = (...args) => {

		if (!getValues("category")) {
			setError("category", {type : "required"});
		}
		handleSubmit ( async (data)  => {
			let dataToSubmit   = {};
			const prices_list  = [];
			const costs_list   = [];

			setLoading(true);

			for (const [key, value] of Object.entries(data)) {
				const isCost = key.split(" ")[0] === "cost";

				if (defaultInputsFiles[key]) {
					dataToSubmit = {
						...dataToSubmit,
						[key] : value,
					};
				} else if (isCost) {
					const id_cost_list =  key.split(" ")[1];
					costs_list.push({
						id_cost_list,
						price : value,
					});
				} else {
					prices_list.push({
						id_price_list : key,
						price         : value,
					});
				}
			}

			const id_category = dataToSubmit.category.value;

			delete dataToSubmit["category"];

			dataToSubmit = {
				...dataToSubmit,
				id_category,
				costs_list,
				prices_list,
				image       : prodcutImg,
				deleteImage : !prodcutImg ? true : false,
			};

			const callback = () => setLoading(false);

			if (editing) {
				const resp = await genericService.update(productToEdit._id, dataToSubmit, "products", dataToSubmit.name, callback);
				resp?.status !== 406 && sidebarActions.closeSidebar();
			} else {
				const resp = await genericService.add(dataToSubmit, "products",  dataToSubmit.name, callback);
				resp?.status !== 406 && sidebarActions.closeSidebar();
			}
		})(...args);
	};

	useEffect(() =>{
		checkPricesList();

		const listSoldier  = [];
		const listRecorded = [];
		const listPulished = [];

		for (let index = 0; index < costs.length; index++) {
			const element = costs[index];

			const newCost = {
				...element,
				id_cost_list : `cost ${element._id}`,
			};

			if (element.type === "soldier") {
				listSoldier.push(newCost);
			} else if (element.type === "recorded") {
				listRecorded.push(newCost);
			} else {
				listPulished.push(newCost);
			}
		}

		if (editing) {
			const list_cost = productToEdit?.costs_list;

			for (let index = 0; index < list_cost?.length; index++) {
				const element = list_cost?.[index];
				setValue(`cost ${element?.id_cost_list}`, element?.price);
			}
		}

		listSoldier.sort(sortList);
		listRecorded.sort(sortList);
		listPulished.sort(sortList);

		console.log("ordendo");

		setCostsSoldier(listSoldier);
		setCostsRecorded(listRecorded);
		setCostsPulished(listPulished);
	}, []);

	return (
		<ProductSidebar
			{...rest}
			editing={editing}
			delegations={{
				prices,
				errors,
				loading,
				register,
				getValues,
				prodcutImg,
				submitForm,
				handleChange,
				costsSoldier,
				costsRecorded,
				costsPulished,
				setProdcutImg,
				handleSearchCategory,
			}}
		/>
	);
};
const mapDispatchToProps = bindAll({ SearcherService, GenericService,  SidebarActions});

const mapStateToProps = ({ _sidebarReducer, _pricesReducer, _costsReducer }) => ({
	open  : _sidebarReducer?.open  ?? false,
	data  : _pricesReducer?.prices ?? [],
	costs : _costsReducer?.costs   ?? [],

});

export default connect(mapStateToProps, mapDispatchToProps)(ProductSidebarContainer);
