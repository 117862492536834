import { ac } from "Helpers";

/**
 * Checks if the role can do the given action in the given resource
 *
 * @author Alcalá León Yael Mártin A. <yael.alcalla@gmail.com>
 * @param {string} role
 * @param {string} action
 * @param {string} resource
 * @returns {{ granted : boolean }}
 */

const can = (role, action, resource) => {
	try {
		const permission = ac.can(role)[action](resource);

		return permission;
	} catch (err) {
		console.error("[VerticalNavMenuItems.canRead] ", err);

		return { granted : false };
	}
};

export default can;
