import { X }          from "react-feather";
import { IconButton } from "@material-ui/core";

const SideBarHeader = ({ 	importFile, editing, sidebarActions, sideBarName }) => {
	return (
		<div className="header d-flex flex-row justify-content-center align-items-center">
			<span className="text flex-grow-1">
				{ editing ? `Editar ${sideBarName}` : (importFile ? `Importar ${sideBarName}` : `Añadir ${sideBarName}`) }
			</span>

			<IconButton className="closeBtn" onClick={sidebarActions.closeSidebar}>
				<X size={18} />
			</IconButton>
		</div>
	);
};
export default SideBarHeader;
