const { START_LOADING_UI_TABLE, CANDEL_LOADING_UI_TABLE } = require("Store/constants");

const startLoading = () => ({ type : START_LOADING_UI_TABLE });
const cancelLoading = () => ({ type : CANDEL_LOADING_UI_TABLE });


const UiTableActions = {
	startLoading,
	cancelLoading,
};

export default UiTableActions;
