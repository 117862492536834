// Import Own Components
// import { firebaseApp } from "FirebaseApp";
import {
	SET_USER_DATA,
	SET_USER_EDITED,
	CLEAR_USER_DATA,
	SET_USER_LENGUAGE,
	SET_SEMIDARK_THEME,
	SET_SOCIAL_LOADING,
	CHANGE_PRIMARY_COLOR,
	SET_USER_COUNT_CONFIRMATION,
} from "Store/constants";

const setUserData = (token, data, social = false) => ({
	type    : SET_USER_DATA,
	payload : {
		token,
		data,
		social,
	},
});

const setLenguage = (lenguage) => ({ type : SET_USER_LENGUAGE, payload : { lenguage } });

const setCountConfirmations = (count_confirmations) => ({ type : SET_USER_COUNT_CONFIRMATION, payload : { count_confirmations } });

const clearUserData = () => dispatch => {
	// const auth = firebaseApp.auth();

	dispatch({ type : CLEAR_USER_DATA });
	// auth.signOut();
};

const setSocialLoading = value => ({
	type    : SET_SOCIAL_LOADING,
	payload : {
		value,
	},
});

const handleSocialLogout = () => (dispatch, getState) => {
	const isSocial = getState()?._authReducer?.userData?.social ?? false;

	// If the current "session" is from google, logout when
	// firebase doesn't detect a user. Else, is our own backend api
	// authentication and we do not need to logout
	if (isSocial) {
		dispatch(clearUserData());
	}
};

const changePrimaryColor = value => ({
	type    : CHANGE_PRIMARY_COLOR,
	payload : {
		value,
	},
});

const setSemiDarkTheme = value => ({
	type    : SET_SEMIDARK_THEME,
	payload : {
		value,
	},
});

const setUserEdited = (data = {}) => ({
	type    : SET_USER_EDITED,
	payload : {
		image    : data.image,
		name     : data.name,
		lastname : data.lastname,
		username : data.username,
		role     : data.role,
	},
});

const AuthActions = {
	setUserData,
	setLenguage,
	setUserEdited,
	clearUserData,
	setSocialLoading,
	setSemiDarkTheme,
	handleSocialLogout,
	changePrimaryColor,
	setCountConfirmations,
};

export default AuthActions;
