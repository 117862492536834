import {
	UPDATE_PRODUCTIONS,
	GET_ALL_PRODUCTIONS,
	UPDATE_PRODUCTOIN_MAQUILA,
} from "Store/constants";


const initialState = {
	docs       : [],
	page       : 1,
	totalPages : 1,
};

const ProductionsReducer = (state = initialState, action) => {
	switch (action.type) {
		case GET_ALL_PRODUCTIONS:
			return {
				docs       : action.payload.data,
				page       : action.payload.page,
				totalDocs  : action.payload.totalDocs,
				totalPages : action.payload.totalPages,
			};
		case UPDATE_PRODUCTIONS:
			const docs = state.docs;
			const id   = action.payload.data._id;

			const newDocs = docs.map(item => {
				if (item._id === id) {
					return {
						...item,
						...action.payload.data,
					};
				}
				return item;
			});

			return {
				...state,
				docs : [...newDocs],
			};
		case UPDATE_PRODUCTOIN_MAQUILA : {
			const newDocs = [ ...state.docs ];

			newDocs[action.payload.index] = action.payload.data;

			return {
				...state,
				docs : [...newDocs],
			};
		}
		default:
			return state;
	}
};

export default ProductionsReducer;
