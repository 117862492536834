import { AccessControl } from "accesscontrol";

const grantsObject = {
	administrator : {
		users : {
			"create:any" : ["*"],
			"read:any"   : ["*"],
			"update:any" : ["*"],
			"delete:any" : ["*"],
		},
		orders : {
			"create:any" : ["*"],
			"read:any"   : ["*"],
			"update:any" : ["*"],
			"delete:any" : ["*"],
		},
		production : {
			"create:any" : ["*"],
			"read:any"   : ["*"],
			"update:any" : ["*"],
			"delete:any" : ["*"],
		},
		desing : {
			"create:any" : ["*"],
			"update:any" : ["*"],
		},
		nav_btns : {
			"read:any" : ["*"],
		},
		clients : {
			"create:any" : ["*"],
			"read:any"   : ["*"],
			"update:any" : ["*"],
			"delete:any" : ["*"],
		},
		reports : {
			"create:any" : ["*"],
			"read:any"   : ["*"],
			"update:any" : ["*"],
		},
	},
	administration : {
		clients : {
			"create:any" : ["*"],
			"read:any"   : ["*"],
			"update:any" : ["*"],
		},
		production : {
			"create:any" : ["*"],
			"read:any"   : ["*"],
			"update:any" : ["*"],
			"delete:any" : ["*"],
		},
		reports : {
			"create:any" : ["*"],
			"read:any"   : ["*"],
			"update:any" : ["*"],
		},
	},
	supervisor : {
		orders : {
			"create:any" : ["*"],
			"read:any"   : ["*"],
			"update:any" : ["*"],
		},
		desing : {
			"update:any" : ["*"],
		},
		production : {
			"read:any"   : ["*"],
			"update:any" : ["*"],
		},
		nav_btns : {
			"read:any" : ["*"],
		},
	},
	operator : {
		production : {
			"read:any"   : ["*"],
			"update:any" : ["*"],
		},
	},
	capturer : {
		orders : {
			"create:any" : ["*"],
			"read:any"   : ["*"],
			"update:any" : ["*"],
		},
	},
	designer : {
		desing : {
			"create:any" : ["*"],
		},
		production : {
			"read:any"   : ["*"],
			"update:any" : ["*"],
		},
		orders : {
			"create:any" : ["*"],
			"read:any"   : ["*"],
			"update:any" : ["*"],
		},
		nav_btns : {
			"read:any" : ["*"],
		},
	},
	client : {
		orders : {
			"create:any" : ["*"],
			"read:any"   : ["*"],
			"delete:any" : ["*"],
		},
		desing : {
			"update:any" : ["*"],
		},
		portal_client : {
			"read:any" : ["*"],
		},
		nav_btns : {
			"read:any" : ["*"],
		},
		"waiting-customer-approval" : {
			"read:any"   : ["*"],
			"update:any" : ["*"],
		},
	},
};

const ac = new AccessControl(grantsObject);

export default ac;
