
import {
	ADD_USERS,
	REMOVE_USERS,
	GET_USERS,
	SET_USERS_ROLE,
	GET_ALL_USERS,
	SET_USERS_ORDER,
	UPDATE_USERS,
	USER_FILTER_CLEAN,
	SET_USERS_ORDERBY,
	UPDATE_STATUS_USER,
	USER_FILTER_STATUS,
} from "Store/constants";

const initialState = {
	users         : [],
	perSistsUsers : [],
	user          : {},
	page          : 1,
	totalPages    : 1,
	role          : [
	],
	orderBy : undefined,
	order   : undefined,
	status  : undefined,
};

const usersReducer = (state = initialState, action) => {
	switch (action.type) {
		case GET_ALL_USERS:
			return {
				...state,
				users         : action.payload.data ?? [],
				perSistsUsers : action.payload.data ?? [],
				page          : action.payload.page ?? [],
				totalPages    : action.payload.totalPages ?? [],
			};
		case SET_USERS_ROLE:
			return {
				...state,
				role : [
					...action.payload.role,
				],
			};
		case SET_USERS_ORDERBY:
			return {
				...state,
				orderBy : action.payload.orderBy,
			};
		case SET_USERS_ORDER:
			return {
				...state,
				order : action.payload.order,
			};
		case REMOVE_USERS: {
			const newUsers = state.users?.filter(user => user._id !== action.payload._id);

			return {
				...state,
				users : newUsers,
			};
		}
		case UPDATE_USERS: {
			const userId = action.payload._id;
			const userData = action.payload.data;
			let position = "";
			const copyState = [...state.users];

			copyState.forEach((obj, i) => {
				if (obj._id === userId) {
					position = i;
				}
			});

			copyState[position] = userData;

			return {
				...state,
				users : copyState,
			};
		}
		case GET_USERS: {
			return {
				...state,
				user : action.payload.data ?? {},
			};
		}
		case UPDATE_STATUS_USER: {
			const userId = action.payload._id;
			const userStatus = action.payload.userStatus;
			const copyState = [...state.users];

			const newUsers = copyState.map(data => {
				const newData = {...data};
				if (newData._id === userId) {
					newData.status = userStatus;
				}
				return newData;
			});
			return {
				...state,
				users : newUsers,
			};
		}
		case USER_FILTER_STATUS: {
			return {
				...state,
				status : action.payload.status,
			};
		}
		case USER_FILTER_CLEAN: {
			return {
				...state,
				status  : undefined,
				orderBy : undefined,
				order   : undefined,
				role    : [],
			};
		}
		case ADD_USERS: {
			const newUser = action.payload.data;

			return {
				...state,
				users : [...state.users, newUser],
			};
		}

		default:
			return state;
	}
};

export default usersReducer;
