import { shallowEqual, useDispatch, useSelector } from "react-redux";

import UiTableActions from "Store/Actions/UiTableActions";

const useLoadingTable = () => {

	const dispatch = useDispatch();

	const { _uiTable : { loading } } = useSelector(state => state, shallowEqual);

	const getTableData = async ( asyncCallback = () => {} ) => {
		dispatch(UiTableActions.startLoading());
		await asyncCallback();
		dispatch(UiTableActions.cancelLoading());
	};

	return { getTableData, loading };
};

export default useLoadingTable;
