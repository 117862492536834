import { forwardRef }          from "react";
import { Input as StrapInput } from "reactstrap";

// TODO: Integrate global theme styles for inputs

/**
 * Forwards the ref from react-hook-form v7 to reactstrap Input's innerRef
 *
 * @author Alcalá León Yael Mártin A. <yael.alcalla@gmail.com>
 */
const Input = forwardRef((props, ref) => (
	<StrapInput
		{...props}
		innerRef={ref}
	/>
));

export default Input;
