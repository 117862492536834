import {
	useRef,
	useMemo,
	useState,
} from "react";
import { connect } from "react-redux";
import { useForm } from "react-hook-form";

// Import Own Components
import { bindAll }    from "Helpers";
import AuthActions    from "Store/Actions/AuthActions";
import GenericService from "Store/Services/GenericService";
import SidebarActions from "Store/Actions/SidebarActions";
import UserSidebar    from "./UserSidebar.jsx";

const UserSidebarContainer = ({
	user,
	userToEdit,
	authActions,
	genericService,
	sidebarActions,
	...rest
}) => {
	const editing = useMemo(() => Boolean(userToEdit), [userToEdit]);

	const password = useRef({});

	const [filesImg, setFilesImg]         = useState(userToEdit?.image ?? null);
	const [chargeButton, setChargeButton] = useState(false);
	const [turn, setTurn] = useState({
		check     : userToEdit?.turn?.check ?? undefined,
		departure : userToEdit?.turn?.departure ?? undefined,
		mealtime  : userToEdit?.turn?.mealtime ?? undefined,
		breakfast : userToEdit?.turn?.breakfast ?? undefined,
	});

	const {
		watch,
		register,
		setValue,
		setError,
		getValues,
		clearErrors,
		handleSubmit,
		formState : { errors },
	} = useForm({
		defaultValues : {
			image    : userToEdit?.image ?? undefined,
			name     : userToEdit?.name ?? "",
			lastname : userToEdit?.lastname ?? "",
			username : userToEdit?.username ?? "",
			role     : userToEdit?.role ?? "",
			email    : userToEdit?.email ?? "",
			notes    : userToEdit?.notes ?? "",
		},
	});

	password.current = watch("password", "");

	const changeTurn = (turnType, value) => {
		setTurn(prev => {
			const newData = {...prev};
			newData[turnType] = value;
			return newData;
		});
	};


	const handleSubmitForm = async (...args) => {

		if (!getValues("role")) {
			setError("role", {type : "required"});
		}
		handleSubmit (async (data) => {
			const callback = (status, data) => {
				if (user?._id === userToEdit?._id && status) {
					authActions.setUserEdited(data);
				}

				setChargeButton(false);
			};

			if (editing && !data?.password) {
				delete data["password"];
				delete data["password2"];
			}

			const dataToSubmit = {
				...data,
				turn,
				image       : filesImg,
				deleteImage : !filesImg ? true : false,
			};

			setChargeButton(true);

			let resp;
			if (editing) {
				resp = await genericService.update(userToEdit._id, dataToSubmit, "users", dataToSubmit.name, callback);
			} else {
				resp = await genericService.add(dataToSubmit, "users", "User", callback);
			}
			resp?.status !== 406 && sidebarActions.closeSidebar();

		})(...args);
	};


	return (
		<UserSidebar
			{...rest}
			delegations={{
				turn,
				errors,
				editing,
				setValue,
				password,
				filesImg,
				register,
				getValues,
				changeTurn,
				clearErrors,
				setFilesImg,
				chargeButton,
				handleSubmitForm,
			}}
		/>
	);
};
const mapStateToProps = ({ _authReducer }) => ({
	user : _authReducer.userData ?? {},
});

const mapDispatchToProps = bindAll({ GenericService, SidebarActions, AuthActions });

export default connect(mapStateToProps, mapDispatchToProps) (UserSidebarContainer);
