import {
	SET_DESING_INFO,
	OPEN_MODAL_DESING,
	CLOSE_MODAL_DESING,
} from "Store/constants";

// Import Own Components

export const initialState = {
	isOpen : false,
	info   : {},
};

const modalDesingReducer = (state = initialState, action) => {
	switch (action.type) {
		case OPEN_MODAL_DESING:
			return {
				...initialState,
				isOpen : true,
			};
		case CLOSE_MODAL_DESING:
			return {
				...initialState,
			};
		case SET_DESING_INFO:
			return {
				...initialState,
				isOpen : true,
				info   : {...action.payload},
			};
		default:
			return state;
	}
};

export default modalDesingReducer;
