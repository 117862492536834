// ** Redux Imports
import { combineReducers } from "redux";

// ** Reducers Imports
import auth   from "./auth";
import navbar from "./navbar";
import layout from "./layout";

export const reducers = {
	auth,
	navbar,
	layout,
};

const rootReducer = combineReducers(reducers);

export default rootReducer;
