import {
	OPEN_EDIT_MODAL_MAQUILA,
	CLOSE_EDIT_MODAL_MAQUILA,
} from "Store/constants";

export const initialState = {
	isOpen        : false,
	maquilaToEdit : {},
};

const modalEditMaquilaReducer = (state = initialState, action) => {
	switch (action.type) {
		case OPEN_EDIT_MODAL_MAQUILA:
			return {
				...initialState,
				isOpen : true,
				...action.payload,
			};
		case CLOSE_EDIT_MODAL_MAQUILA:
			return {
				...initialState,
			};
		default:
			return state;
	}
};

export default modalEditMaquilaReducer;
