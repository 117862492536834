import { ReactSelectSearcher } from "Helpers";

const getClients         = ReactSelectSearcher.serviceFn("clients");
const getCategories      = ReactSelectSearcher.serviceFn("categories");
const getMakeupArtists   = ReactSelectSearcher.serviceFn("makeupArtists");
const getProductsGrouped = ReactSelectSearcher.serviceFn("/products/group/category");
const getFigures         = ReactSelectSearcher.serviceFn("accessories", { page : 1, type : "figure"});
const getColors          = ReactSelectSearcher.serviceFn("accessories", { page : 1, type : "colors"});
const getMeasures        = ReactSelectSearcher.serviceFn("accessories", { page : 1, type : "measure"});
const getMaterials       = ReactSelectSearcher.serviceFn("accessories", { page : 1, type : "material"});
const getSoldiers        = ReactSelectSearcher.serviceFn("makeupArtists", { page : 1, type : "soldier" });
const getPolishers       = ReactSelectSearcher.serviceFn("makeupArtists", { page : 1, type : "polished" });
const getRecorded        = ReactSelectSearcher.serviceFn("makeupArtists", { page : 1, type : "recorded" });

const SearcherService = {
	getColors,
	getClients,
	getFigures,
	getMeasures,
	getSoldiers,
	getRecorded,
	getMaterials,
	getPolishers,
	getCategories,
	getMakeupArtists,
	getProductsGrouped,
};

export default SearcherService;
