import {
	GET_COMMENTS,
	ADD_COMMENTS,
	CLEAR_COMMENTS,
} from "Store/constants";

const initialState = {
	docs       : [],
	totalDocs  : 0,
	page       : 1,
	totalPages : 1,
	limit      : 20,
};

const commentsReducer = (state = initialState, action) => {
	switch (action.type) {
		case GET_COMMENTS: {
			const newDocs = [ ...state.docs, ...action.payload.data.docs];
			return {
				...state,
				...action.payload.data,
				docs : newDocs,
			};
		}
		case ADD_COMMENTS: {
			const newDocs = [ { ...action.payload.data }, ...state.docs];

			if (action.payload.totalDocs >  state.limit * action.payload.totalPages) newDocs.pop();

			return {
				...state,
				docs      : newDocs,
				totalDocs : state.totalDocs + 1,
			};
		}
		case CLEAR_COMMENTS: {
			return initialState;
		}
		default:
			return state;
	}
};

export default commentsReducer;
