import {
	OPEN_SIDEBAR,
	CLOSE_SIDEBAR,
} from "Store/constants";

const openSidebar = (which, props = {}) => ({
	type    : OPEN_SIDEBAR,
	payload : {
		[which] : true,
		props,
	},
});

const closeSidebar = () => ({ type : CLOSE_SIDEBAR });

const SidebarActions = {
	openSidebar,
	closeSidebar,
};

export default SidebarActions;
