import { useCallback, useEffect, useState } from "react";
import { useDropzone }                      from "react-dropzone";
import DropZoneImg                          from "./DropZoneImg";

const DropZoneImgContainer = ({
	image = null,
	defaultImage = null,
	callback = () => {},
	setImage = () => {},
	clearError = () => {},
	customErrors = () => {},
	backgroundSize = "cover",
	setCustomErrors = () => {},
	classNm = "image-container",
}) => {
	const [imageUrl, setImageUrl] = useState(image);

	const updateImageUrl = () => {
		try {
			if (imageUrl) {
				URL.revokeObjectURL(imageUrl);
			}

			if (image && image instanceof File) {
				setImageUrl(URL.createObjectURL(image));
			} else if (image) {
				setImageUrl(image);
			}  else if (!image) {
				setImageUrl("");
			}
		} catch (err) {
			console.error("[AboutCompanyContainer] ", err);
		}
	};

	const onDrop = useCallback(acceptedFiles => {
		if (!Array.isArray(acceptedFiles)) {
			setCustomErrors("image", "Tienes que elegir una imagen.");
			return;
		}

		if (acceptedFiles.length !== 1) {
			setCustomErrors("image", "Solo puedes seleccionar un archivo.");
			return;
		}

		const selectedImage = acceptedFiles[0];

		if (!(selectedImage instanceof File)) {
			setCustomErrors("image", "El formato de la imagen no es válido.");
			return;
		}

		const fiveMegaBytes = 5242880;

		if (selectedImage.size >= fiveMegaBytes) {
			setCustomErrors("image", "La imagen es demasiado pesada. Peso máximo de 5MB.");
			return;
		}

		clearError("image");
		setImage(selectedImage);
	}, []);

	const { getRootProps, getInputProps } = useDropzone({
		accept : "image/*",
		onDrop,
	});

	useEffect(() => {
		updateImageUrl();

		return () => {
			setImageUrl(null);
			URL.revokeObjectURL(imageUrl);
		};
	}, [image]);

	return (
		<DropZoneImg
			delegations={{
				imageUrl,
				classNm,
				setImage,
				callback,
				defaultImage,
				getRootProps,
				customErrors,
				getInputProps,
				backgroundSize,
			}}
		/>
	);
};
export default DropZoneImgContainer;
