import {
	OPEN_MODAL_CATEGORY,
	CLOSE_MODAL_CATEGORY,
} from "Store/constants";

// Import Own Components

export const initialState = {
	isOpen : false,
	props  : {},
};

const modalCategoryReducer = (state = initialState, action) => {
	switch (action.type) {
		case OPEN_MODAL_CATEGORY:
			return {
				...initialState,
				isOpen : true,
				...action.payload,
			};
		case CLOSE_MODAL_CATEGORY:
			return {
				...initialState,
			};
		default:
			return state;
	}
};

export default modalCategoryReducer;
