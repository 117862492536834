import {
	GET_ADDRESSES,
	UPDATE_ADDRESSES,
	GET_ALL_ADDRESSES,
} from "Store/constants";

const initialState = {
	addresses  : [],
	address    : {},
	page       : 1,
	totalPages : 1,
};

const clientsReducer = (state = initialState, action) => {
	switch (action.type) {
		case GET_ALL_ADDRESSES:
			return {
				...state,
				addresses  : action.payload.data ?? [],
				page       : action.payload.page ?? [],
				totalPages : action.payload.totalPages ?? [],
			};
		case GET_ADDRESSES: {
			return {
				...state,
				address : action.payload.data ?? {},
			};
		}
		case UPDATE_ADDRESSES: {
			const addressId   = action.payload._id;
			const addressData = action.payload.data;
			let position = "";
			const copyState = [...state.addresses];

			copyState.forEach((obj, i) => {
				if (obj._id === addressId) {
					position = i;
				}
			});

			copyState[position] = addressData;

			return {
				...state,
				addresses : copyState,
			};
		}
		default:
			return state;
	}
};

export default clientsReducer;
