import classnames  from "classnames";
import Flatpickr   from "react-flatpickr";
import { Spanish } from "flatpickr/dist/l10n/es";

import {
	FormGroup, Label,
} from "reactstrap";

import "./FlatPickrGroup.scss";

const FlatPickrGroup = ({
	name = "",
	label = "",
	errors = {},
	required = true,
	disabled = false,
	placeholder = "",
	handleChange = () => {},
	options = { locale : Spanish, dateFormat : "d-m-Y" },
	...rest
}) => {
	return (
		<FormGroup id="FlatPickrGroup">
			<Label
				className={classnames("text-label")}
			>
				{ `${label} ${required ? "*" : "" }` }
			</Label>
			<Flatpickr
				{...rest}
				options={options}
				disabled={disabled}
				onChange={handleChange}
				placeholder={placeholder}
				className={classnames("form-control flatpickr-input", {
					invalid         : (errors?.[name]?.type),
					"disabled-inpt" : disabled,
				})}
			/>
			{errors?.[name]?.type && (
				<p style={{ color : "#EA5455", fontSize : "12px", marginTop : "3px", marginBottom : "0" }}>
					Este campo es obligatorio.
				</p>
			)}
		</FormGroup>
	);
};
export default FlatPickrGroup;
