import {
	OPEN_MODAL_MAKEUP_ARTIST,
	CLOSE_MODAL_MAKEUP_ARTIST,
} from "Store/constants";

// Import Own Components

export const initialState = {
	isOpen             : false,
	makeupArtistToEdit : {},
};

const modalMakeupArtistReducer = (state = initialState, action) => {
	switch (action.type) {
		case OPEN_MODAL_MAKEUP_ARTIST:
			return {
				...initialState,
				isOpen : true,
				...action.payload,
			};
		case CLOSE_MODAL_MAKEUP_ARTIST:
			return {
				...initialState,
			};
		default:
			return state;
	}
};

export default modalMakeupArtistReducer;
