import qs from "qs";

// Import Own Components
import fetchClient from "./fetchClient";

class ReactSelectSearcher {
	static serviceFn = (module, extraQuery = {}) => (search = "") => async (dispatch, getState) => {
		try {
			const { token } = getState()._authReducer;

			const query = {
				page : 1,
				search,
				...extraQuery,
			};

			const response = await fetchClient(`/${module}?${qs.stringify(query)}`, { token });

			if (!response?.ok) {
				throw response;
			}

			const { docs } = await response.json();

			return Array.isArray(docs) ? docs : [];
		} catch (err) {
			console.error("[ReactSelectSearcher] Service fn error: ", err);
		}
	};

	static containerFn = (serviceMethod, isFormatted) => async (value, callback) => {
		try {
			const response = await serviceMethod(value);
			let data = [];

			if (isFormatted) {
				data = await response;
				callback(data);
				return;
			}

			for (const value of response) {
				if (value?._id && value?.name) {
					data.push({
						...value,
						value : value._id,
						label : value.name,
					});
				}
			}
			callback(data);
		} catch (err) {
			console.error("[ReactSelectSearcher] Container fn error: ", err);
		}
	};
}

export default ReactSelectSearcher;
