import classNames from "classnames";

import {
	Label,
	FormGroup,
	InputGroup,
	InputGroupAddon,
	InputGroupText,
} from "reactstrap";
import { useTranslation } from "react-i18next";

import Input from "Components/Input";

const FormGroupInput = ({
	name = "",
	label = "",
	Icon = null,
	errors = {},
	type = "text",
	register = () => {},
	options = { required : true },
	feedBack = "Este campo no puede estar vacío",
	...rest
}) => {
	const { t } = useTranslation();

	return (
		<FormGroup id="FormGroupInput">
			<Label className="form-label" for={`register-${name}`}>
				{label + (options.required ? "*" : "")}
			</Label>
			<InputGroup className={classNames("formInput mb-0", {
				invalid : errors?.[name]?.type,
			})}>
				{
					Icon &&
					<InputGroupAddon addonType="prepend">
						<InputGroupText>
							<Icon size={15} />
						</InputGroupText>
					</InputGroupAddon>
				}
				<Input
					{...rest}
					id={`register-${name}`}
					type={type}
					name={name}
					invalid={!!errors?.[name]?.type}
					{...register(name, { ...options })}
				/>
			</InputGroup>
			{ errors?.[name]?.type && (
				name === "email" ?
					<p  style={{ color : "#EA5455", fontSize : "12px", marginTop : "3px", marginBottom : "0" }}>
						{t("feedBack.email")}
					</p>
					:
					<p  style={{ color : "#EA5455", fontSize : "12px", marginTop : "3px", marginBottom : "0" }}>
						{feedBack ? feedBack : t("feedBack.not_empty")}
					</p>
			) }
		</FormGroup>
	);
};
export default FormGroupInput;
