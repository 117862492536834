import {
	GET_CLIENTS,
	ADD_CLIENTS,
	REMOVE_CLIENTS,
	UPDATE_CLIENTS,
	GET_ALL_CLIENTS,
} from "Store/constants";

const initialState = {
	clients         : [],
	perSistsClients : [],
	client          : {},
	page            : 1,
	totalPages      : 1,
};

const clientsReducer = (state = initialState, action) => {
	switch (action.type) {
		case GET_ALL_CLIENTS:
			return {
				...state,
				clients         : action.payload.data ?? [],
				perSistsClients : action.payload.data ?? [],
				page            : action.payload.page ?? [],
				totalPages      : action.payload.totalPages ?? [],
			};
		case REMOVE_CLIENTS: {
			const newClients = state.clients?.filter(client => client._id !== action.payload._id);

			return {
				...state,
				clients : newClients,
			};
		}
		case UPDATE_CLIENTS: {
			const clientId   = action.payload._id;
			const clientData = action.payload.data;
			let position = "";
			const copyState = [...state.clients];

			copyState.forEach((obj, i) => {
				if (obj._id === clientId) {
					position = i;
				}
			});

			copyState[position] = clientData;

			return {
				...state,
				clients : copyState,
			};
		}
		case GET_CLIENTS: {
			return {
				...state,
				client : action.payload.data[0] ?? {},
			};
		}
		case ADD_CLIENTS: {
			const newClient = action.payload.data;

			return {
				...state,
				clients : [...state.clients, newClient],
			};
		}

		default:
			return state;
	}
};

export default clientsReducer;
