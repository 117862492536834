import { connect } from "react-redux";
import classnames  from "classnames";
import { Slide }   from "@material-ui/core";

// Import Own Components
import { bindAll }    from "Helpers";
import SidebarActions from "Store/Actions/SidebarActions";
import UserSidebar    from "./UserSidebar";
import ProductSidebar from "./ProductSidebar";
import "./Sidebar.scss";

/** Define all the sidebars of the app and their import */
export const sidebarsList = {
	userSidebar    : UserSidebar,
	productSidebar : ProductSidebar,
};

const Sidebar = ({ sidebarActions, sidebarReducer }) => {
	const { open, props, ...allSidebars } = sidebarReducer;
	return (
		<div id="Sidebar">
			<div
				className={classnames("veil", { open })}
				onClick={sidebarActions.closeSidebar}
			/>

			{/* All sidebars */}
			{ Object.entries(allSidebars).map(([ key, value ]) => {
				const Component = sidebarsList[key];

				return (
					<Slide
						key={key}
						in={value}
						direction="left"
						mountOnEnter
						unmountOnExit
					>
						<div className="modal">
							<Component {...props} />
						</div>
					</Slide>
				);
			}) }
		</div>
	);
};

const mapStateToProps = ({ _sidebarReducer }) => ({ sidebarReducer : _sidebarReducer });

const mapDispatchToProps = bindAll({ SidebarActions });

export default connect(mapStateToProps, mapDispatchToProps)(Sidebar);
