import {
	GET_MAKEUPARTISTS,
	ADD_MAKEUPARTISTS,
	GET_ALL_MAKEUPARTISTS,
	CLEAR_MAKEUPARTISTS_DATA,
} from "Store/constants";

const initialState = {
	makeupArtists : [],
	makeupArtist  : {},
	page          : 1,
	totalPages    : 1,
};

const makeupArtistReducer = (state = initialState, action) => {
	switch (action.type) {
		case GET_MAKEUPARTISTS:
			return {
				...state,
				makeupArtist : action.payload.data,
			};
		case GET_ALL_MAKEUPARTISTS:
			return {
				...state,
				makeupArtists : action.payload.data,
				page          : action.payload.page,
				totalPages    : action.payload.totalPages,
			};
		case ADD_MAKEUPARTISTS: {
			const makeupArtists = [...state.makeupArtists];

			makeupArtists.push(action.payload.data);
			return {
				...state,
				makeupArtists,
			};
		}
		case CLEAR_MAKEUPARTISTS_DATA: {
			return initialState;
		}
		default:
			return state;
	}
};

export default makeupArtistReducer;
