import { ArrowRight } from "react-feather";
import { components } from "react-select";

const CategorySelectOptions = (props) => {
	return (
		<components.Option
			{...props}
			type="button"
		>
			<div style={{ display : "grid",  gridTemplateColumns : "2fr 1fr 2fr" }}>
				{
					props.data.parent.name !== "" &&
						<>
							<span>{ props.data.parent.name}</span>
							<ArrowRight size={15} />
						</>
				}
				<span style={{  fontWeight : "bold" }}>{ props.data.label }</span>
			</div>
		</components.Option>
	);
};

export default CategorySelectOptions;
