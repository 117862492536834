import qs from "qs";

// Import Own Components
import {
	fetchClient,
	isValidArray,
	dispatchToaster,
} from "Helpers";
import GenericActions from "../Actions/GenericActions";


const getAll = (type, query, callback = () => {}, exeDispatch = true) => async (dispatch, getState) => {
	try {
		const { _authReducer : { token, lenguage = "" } } = getState();

		if (query && isNaN(query?.page)) {
			query["page"] = 1;
		}

		const response = await fetchClient(`/${type}?${qs.stringify(query)}`, { token, lenguage } );

		const data = await response.json();

		if (data?.docs) {
			if (exeDispatch) {
				dispatch(GenericActions.getAll(data, type));
			}
			callback(data.docs);
			return data;
		} else {
			if (exeDispatch) {
				dispatch(GenericActions.getAll(data, type));
			}
			return {};
		}
	} catch (err) {
		console.error("[Error GerincServices] getAll", err);
		return err;
	}
};

const get = (_id, type, callback = () => {}, query) => async (dispatch, getState) => {
	try {

		const { _authReducer : { token, lenguage = "" } } = getState();

		const response = await fetchClient(`/${type}/${_id}?${qs.stringify(query)}`, { token, lenguage } );

		const {
			data,
		} = await response.json();

		if (!data) {
			return response;
		}
		callback(data);
		dispatch(GenericActions.get(data, type));

		return data;
	} catch (err) {
		console.error("[Error GerincServices] get", err);
		return err;
	}

};

const add = (data = {}, type,  identifier, callback = () => {}) => async (dispatch, getState) => {
	const { _authReducer : { token, lenguage = "" } } = getState();
	try {

		const body = buildFormData(data);

		const response = await fetchClient(`/${type}`, { token, method : "POST", body, lenguage } );

		if (response?.status === 200) {
			const {data} = await response.json();
			dispatch(GenericActions.add(data, type));
			callback(data);
		} else {
			callback();
		}


		dispatchToaster("ADD", response?.status, identifier, lenguage);

		return response;
	} catch (err) {
		callback();
		console.error("error", err);
		dispatchToaster("ADD", 500, lenguage);
		return err;
	}
};

const update = (_id, data = {}, type, identifier, callback = () => {}) => async (dispatch, getState) => {
	const { _authReducer : { token, lenguage = "" } } = getState();
	try {

		const body = buildFormData(data);

		const response = await fetchClient(`/${type}/${_id}`, { token, method : "PUT", body, lenguage } );

		if (response?.status === 200) {
			const {data} = await response.json();
			dispatch(GenericActions.update(data, _id, type));

			callback(response?.status === 200, data);
		} else {
			callback(response?.status === 200);
		}

		dispatchToaster("UPDATE", response?.status, identifier, lenguage);

		return response;
	} catch (err) {
		callback();
		console.error("[Error GerincServices] update", err);
		dispatchToaster("UPDATE", 500, lenguage);
		return err;
	}
};

const patch = (_id, data = {}, type, identifier, callback = () => {}, extraType = "") => async (dispatch, getState) => {
	const { _authReducer : { token, lenguage = "" } } = getState();
	try {

		const body = new FormData();
		const existData = Object.keys(data).length > 0;

		if (existData) {
			for (const item in data) {
				if (isValidArray(data[item])) {
					data[item].forEach(element => {
						body.append(`${item}[]`, JSON.stringify(element));
					});
				} else if (typeof data[item] === "object") {
					body.append(item, JSON.stringify(data[item]));
				} else {
					body.append(item, data[item]);
				}
			}
		}
		let response;

		if (_id) {
			response = await fetchClient(`/${type}/${_id}/${extraType}`, { token, method : "PATCH", body, lenguage } );
		} else {
			response = await fetchClient(`/${type}/${extraType}`, { token, method : "PATCH", body, lenguage } );
		}


		if (response?.status === 200) {
			const { data = {} } = await response.json();

			callback(data, response?.status);

			if (existData) {
				dispatch(GenericActions.update(data, _id, type));
			}
		} else {
			callback(null, response?.status);
		}

		dispatchToaster("UPDATE", response?.status, identifier, lenguage);

		return response;
	} catch (err) {
		callback();
		console.error("[Error GerincServices] patch", err);
		dispatchToaster("UPDATE", 500, lenguage);
		return err;
	}
};


const remove = (id, type, identifier, callback = () => {}) => async (dispatch, getState) => {
	const { _authReducer : { token, lenguage = "" } } = getState();
	try {

		const response = await fetchClient(`/${type}/${id}`, { token, method : "DELETE", lenguage } );

		if (response?.status === 200) {
			dispatch(GenericActions.remove(id, type));
		}

		callback(response?.status === 200);
		dispatchToaster("REMOVE", response?.status, identifier, lenguage);

		return response;
	} catch (err) {
		callback();
		dispatchToaster("REMOVE", 500, lenguage);
		console.error("[Error GerincServices] remove", err);
		return;
	}

};

const buildFormData = (data = null) => {
	const body = new FormData();

	if (!data) return body;

	for (const item in data) {

		//this is a special case for the files, on OrderCreate module, we have a case that the client can send an array of files
		if (item === "files_client") {

			data[item].forEach(element => {

				if (element instanceof File) {
					body.append("files_client", element);
				} else {

					const url  = element.url;
					const type = element.url.split(".").pop();

					const blob = url.slice(0, url.size, type);

					const newFile = new File([blob], url, {type : type});
					body.append("files_client", newFile);
				}
			});

		} else if (isValidArray(data[item])) {

			data[item].forEach(element => {

				if (element instanceof File) {
					body.append(item, element);
				} else {
					body.append(`${item}[]`, JSON.stringify(element));
				}

				//this is a special case for the files, on OrderCreate module, we have a case that the user can upload an image in a repetear form
				if (element?.image) {
					body.append("files", element.image);
				}
			});
		} else if (typeof data[item] === "object") {

			body.append(item, JSON.stringify(data[item]));

			if (data[item] instanceof File) {
				body.append("file", data[item]);
			}

		} else {
			if (data[item] !== undefined) {
				body.append(item, data[item]);
			}
		}
	}
	return body;
};

const GenericService = {
	add,
	get,
	patch,
	update,
	remove,
	getAll,
};

export default GenericService;
