import {
	SET_TOTAL_DOCS_PRE,
	FILTER_BY_PENDING_USER,
	CANCEL_PENDING_FILTERS,
	FILTER_BY_PRE_PRODUCTION,
	GET_ALL_ORDERS_FILTER_USER,
	GET_ALL_ORDERS_FILTER_CLIENT,
	CANCEL_PRE_PRODUCTION_FILTERS,

	GET_ALL_WAITING_CUSTOMER_APPROVAL,
	CANCEL_WAITING_CUSTOMER_APPROVAL,
	FILTER_BY_WAITING_CUSTOMER_APPROVAL,
} from "Store/constants";

export const initialState = {
	totalDocs           : null,
	totalDocsPre        : null,
	pending             : false,
	preProductionFilter : false,

	totalDocsWCP            : null,
	wattingCustomerApproval : false,
};

const navBarPendingBtnsReducer = (state = initialState, action) => {
	switch (action.type) {
		case GET_ALL_ORDERS_FILTER_USER:
			return {
				...state,
				...action.payload.data,
			};
		case GET_ALL_ORDERS_FILTER_CLIENT:
			return {
				...state,
				...action.payload.data,
			};
		case FILTER_BY_PENDING_USER:
			return {
				...state,
				pending : true,
			};
		case SET_TOTAL_DOCS_PRE:
			return {
				...state,
				totalDocsPre : action.payload.totalDocsPre,
			};
		case FILTER_BY_PRE_PRODUCTION:
			return  {
				...state,
				preProductionFilter : true,
			};
		case CANCEL_PENDING_FILTERS:
			return {
				...state,
				pending : false,
			};
		case CANCEL_PRE_PRODUCTION_FILTERS :
			return {
				...state,
				preProductionFilter : false,
			};

		case GET_ALL_WAITING_CUSTOMER_APPROVAL:
			return {
				...state,
				...action.payload.data,
			};
		case FILTER_BY_WAITING_CUSTOMER_APPROVAL:
			return {
				...state,
				wattingCustomerApproval : true,
			};
		case CANCEL_WAITING_CUSTOMER_APPROVAL:
			return {
				...state,
				wattingCustomerApproval : false,
			};

		default:
			return state;
	}
};

export default navBarPendingBtnsReducer;
