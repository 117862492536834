import {
	SET_USER_DATA,
	CLEAR_USER_DATA,
	SET_USER_EDITED,
	SET_USER_LENGUAGE,
	SET_SEMIDARK_THEME,
	SET_SOCIAL_LOADING,
	CHANGE_PRIMARY_COLOR,
	SET_USER_COUNT_CONFIRMATION,
} from "Store/constants";

const initialState = {
	loggedIn : false,
	token    : "",
	userData : {
		preferences : {
			semidark : false,
		},
	},
	socialLoading : false,
	lenguage      : "",
};

const authReducer = (state = initialState, action) => {
	switch (action.type) {
		case SET_USER_DATA:
			return {
				...state,
				loggedIn      : true,
				token         : action?.payload?.token ?? state.token,
				socialLoading : false,
				userData      : {
					...action.payload.data,
				},
			};
		case SET_SOCIAL_LOADING:
			return {
				...state,
				socialLoading : action.payload.value,
			};
		case SET_USER_LENGUAGE:
			return {
				...state,
				lenguage : action.payload.lenguage,
			};
		case CHANGE_PRIMARY_COLOR:
			return {
				...state,
				userData : {
					...(state?.userData ?? {}),
					preferences : {
						...(state?.userData?.preferences ?? {}),
						primary_color : action.payload.value,
					},
				},
			};
		case SET_SEMIDARK_THEME:
			return {
				...state,
				userData : {
					...(state?.userData ?? {}),
					preferences : {
						...(state?.userData?.preferences ?? {}),
						semidark : action.payload.value,
					},
				},
			};
		case SET_USER_EDITED:
			return {
				...state,
				userData : {
					...state.userData,
					...action.payload,
				},
			};
		case SET_USER_COUNT_CONFIRMATION:
			return {
				...state,
				userData : {
					...state.userData,
					...action.payload,
				},
			};
		case CLEAR_USER_DATA:
			return initialState;
		default:
			return state;
	}
};

export default authReducer;
