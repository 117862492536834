
import {
	CANDEL_LOADING_UI_TABLE,
	START_LOADING_UI_TABLE,
} from "Store/constants";

const initialState = {
	loading : false,
};

const uiTable = (state = initialState, action) => {
	switch (action.type) {
		case START_LOADING_UI_TABLE:
			return { loading : true };
		case CANDEL_LOADING_UI_TABLE:
			return { loading : false };
		default:
			return state;
	}
};

export default uiTable;
