import {
	OPEN_MODAL_REPORT,
	CLOSE_MODAL_REPORT,
} from "Store/constants";

// Import Own Components

export const initialState = {
	isOpen : false,
	info   : {},
};

const modalReportReducer = (state = initialState, action) => {
	switch (action.type) {
		case OPEN_MODAL_REPORT:
			return {
				...initialState,
				isOpen : true,
				...action.payload,
			};
		case CLOSE_MODAL_REPORT:
			return {
				...initialState,
			};
		default:
			return state;
	}
};

export default modalReportReducer;
