export const TRANSLATIONS_EN = {
	unseen                : "Unseen",
	count                 : "count",
	clean_filter          : "Clean Filter",
	filter                : "Filter",
	seen                  : "Seen",
	new                   : "New",
	new_plural            : "New",
	notifications         : "Notifications",
	empty                 : "Empty",
	preorder              : "Orders",
	lenguage              : "Language",
	role                  : "Rol",
	"0"                   : "Inactive",
	"1"                   : "Active",
	designer              : "Designer",
	operator              : "Operator",
	supervisor            : "Supervisor",
	priorities            : "Priorities",
	administrator         : "Admin",
	loading               : "Loading",
	back                  : "Back",
	next                  : "Next",
	date_format           : "{{date, MMMM DD, YYYY}}",
	date_format_number    : "MM/DD/YYYY hh:mm A",
	date_format_value     : "MM/DD/YYYY",
	date_format_flatpickr : "m/d/Y",
	logout                : "Logout",
	home                  : "Home",
	cancel                : "Cancel",
	orders                : "Orders",
	line                  : "Line",
	date_delivery         : "Estimated delivery date",
	quantity              : "Quantity",
	figure                : "Figure",
	measure               : "Measure",
	engraved_behind       : "Engraved from Behind",
	letters_behind        : "Letters Behind",
	specs                 : "Specifications",
	stone_color           : "Stone Color",
	material              : "Material",
	download              : "Download",
	save_pdf              : "Save PDF",
	remove                : "Remove",
	pending               : "Design",
	in_progress           : "In progress",
	completed             : "Completed",
	rejected              : "Rejected",
	process               : "Process",
	approved              : "Approved",
	desings               : "Designs",
	files                 : "Files",
	folio                 : "Folio",
	status                : "Status",
	drag_click_new_file   : "Drag or click to add a file",
	notify_client         : "I want to notify the customer.",
	last_movements        : "Last Movements",
	priority              : "Priority",
	approval              : "approval",
	actions               : "Actions",
	products              : "Products",
	pieces                : "Pieces",
	price                 : "Price",
	image_optional        : "Image optional",
	specs_optional        : "Specifications optional",
	figure_optional       : "Figure optional",
	optional              : "Optional",
	critical              : "Critical",
	low              	    : "Low",
	medium               	: "Medium",
	recover_password      : {
		title     : "Forgot your password?",
		body      : "Enter your email address to retrieve your password.",
		label     : "Email",
		label_btn : "Submit",
		back      : "Back",
	},
	comment : {
		comment      : "Comment",
		comments     : "Comments",
		comment_sing : "Comment",
		add          : "Add a comment",
		no_comments  : "No comments",
	},
	address : {
		address_shipping    : "Shipping Address",
		address_facturation : "Billing Address",
	},
	order : {
		check_wca              : "Marks as waiting for customer approval",
		order_name_label       : "Order Name",
		order_name_placeholder : "Enter the order name",
		viwed                  : "Seen",
		show                   : "Show all form",
		label_pending_filter   : "Filtring by pending",
		label_wca_filter       : "Filtring by Waiting for approval",
		fillForm               : "Fill Out form",
		dowloandOrder          : "Order's files",
		in_review              : "In review",
		accepted               : "Accepted",
		add_order              : "New Order",
		edit_order             : "Edit Order",
		order_detail           : "Order detail",
		order                  : "Order",
		pending                : "In design",
		in_progress            : "In production",
		completed              : "Completed",
		total_pieces           : "Total pieces",
		amount                 : "Amount",
		save_order             : "Save Order",
		history                : {
			edited          : "Order has been edited",
			created         : "Order has been created",
			new_layout      : "A new design has been added",
			design_approved : "Design hase been approved",
			design_pending  : "Design has been changed to pending status",
		},

		alert : {
			title : "Forbidden",
			msg   : "This orden cannot be modified, because a worker has accepted it",
		},
	},
	desing : {
		submit_new_desing   : "Submit new design",
		update_status_title : "Update Design State",
		update_status_msg   : "¿Are you sure you want to change the design state?",
	},
	client : {
		client      : "Client",
		info_client : "Client information",
	},
	product : {
		product     : "Product",
		piece       : "Piece",
		add_product : "Add product",
	},
	dropZone : {
		drag_file_here    : "Attach files, add a maximum of {{maxFiles}} files",
		attach            : "Attach files",
		title_error      	: "An error has occurred",
		body_error       	: "Something went wrong while uploading the files, please verify that the limit of allowed files has not been exceeded.",
		limit_title_error : "Limit exceeded",
		limit_body_error  : "You have exceeded the limit of {{maxFiles}} allowed files, please remove some files to upload more.",
	},
	placeholder : {
		search               : "Search",
		select               : "Select",
		enter_address        : "Enter the address",
		select_date_delivery : "Select the date of delivery",
		select_product       : "Select a product",
		select_client        : "Select a client",
		enter_price_product  : "Enter the price of the product",
		enter_quantity       : "Enter the quantity",
		max_characters       : "Max characters",
		select_figure        : "Select a figure",
		write_letters        : "Write the letters",
		select_measure       : "Select a measure",
		select_stone_color   : "Select a stone color",
		select_material      : "Select a material",
		enter_price          : "Enter the price of the product",
	},
	feedBack : {
		email            : "Enter a valid email format",
		file             : "You must upload a file",
		not_empty        : "This field can not be empty",
		quantity         : "You must enter an amount and the amount cannot be less than 1.",
		address          : "You must enter an address",
		address_shipping : "You must enter an address",
		date_delivery    : "You must enter a date of delivery",
		product          : "You must enter a product",
		client           : "You must enter a client",
		price            : "You must enter a price",
		measure          : "You must enter a measure",
		stone_color      : "You must enter a stone color",
		material         : "You must enter a material",
		figure           : "You must enter a figure",
		letters          : "You must enter letters",
	},
	modals : {
		delete_order : {
			question    : "Are you sure you want to delete the order",
			description : "We will permanently delete the order. This action cannot be undone",
		},
	},
	roles : {
		administrator  : "Administrator",
		supervisor     : "Supervisor",
		operator       : "Operator",
		designer       : "Designer",
		capturer       : "Capturer",
		client         : "Client",
		administration : "Administrative",
	},
	login_client : {
		welcome           : "Welcome Back!",
		msg_access        : "<p>Enter your data to access the app</p>",
		email             : "Email",
		email_placeholder : "Enter email",
		password          : "Password",
		recover_password  : "Do you forget the password?",
		loading           : "Loading",
		login             : "Login",
		inprodi_msg       : "Developed with ❤️ by Inprodi Web Studio.",
		lan               : "Language",
	},
	tutorial : {
		header : "Tutorial",
		title  : "Filtering by pending designs",
		text   : "This filter shows all the new comments of an order or when there are unaccepted designs.",
		accept : "Accept",
	},
};
