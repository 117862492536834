import {
	SET_DOWLOAD_PDF_INFO,
	OPEN_MODAL_DOWLOAD_PDF,
	CLOSE_MODAL_DOWLOAD_PDF,
} from "Store/constants";

// Import Own Components

export const initialState = {
	isOpen : false,
	info   : [],
};

const modalDowloadPdf = (state = initialState, action) => {
	switch (action.type) {
		case OPEN_MODAL_DOWLOAD_PDF:
			return {
				...initialState,
				isOpen : true,
			};
		case CLOSE_MODAL_DOWLOAD_PDF:
			return {
				...initialState,
			};
		case SET_DOWLOAD_PDF_INFO:
			return {
				...initialState,
				isOpen : true,
				info   : [...action.payload],
			};
		default:
			return state;
	}
};

export default modalDowloadPdf;
