import { toast }      from "react-toastify";
import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles(theme => ({
	delet : {
		height              : "100%",
		display             : "flex",
		width               : "100%",
		paddingLeft         : "13px",
		"& .body-container" : {
			display    : "flex",
			alignItems : "center",

			"& .container-icon" : {
				marginRight : "13px",
				marginLeft  : "13px",
				color       : "#F64E60",
			},

			"& .conatiner-info" : {
				"& .text-legend" : {
					color      : "#F64E60",
					fontSize   : "14px",
					fontWeight : "normal",
					lineHeught : "18px",
				},

				"& .full-info" : {
					fontSize : "12px",

					"& .focus-data" : {
						color     : "#464E5F",
						fotWeight : "bolder",
					},
				},
			},
		},
	},

	edit : {
		height              : "100%",
		display             : "flex",
		width               : "100%",
		paddingLeft         : "13px",
		"& .body-container" : {
			display    : "flex",
			alignItems : "center",

			"& .container-icon" : {
				marginRight : "13px",
				marginLeft  : "13px",
				color       : "#1F67B0",
			},

			"& .conatiner-info" : {
				"& .text-legend" : {
					color      : "#1F67B0",
					fontSize   : "14px",
					fontWeight : "normal",
					lineHeught : "18px",
				},

				"& .full-info" : {
					fontSize : "12px",

					"& .focus-data" : {
						color     : "#464E5F",
						fotWeight : "bolder",
					},
				},
			},
		},
	},

	add : {
		height              : "100%",
		display             : "flex",
		width               : "100%",
		paddingLeft         : "0px !important",
		"& .body-container" : {
			display    : "flex",
			alignItems : "center",

			"& .container-icon" : {
				marginRight : "13px",
				marginLeft  : "13px",
				color       : "#2A9C5B",
			},

			"& .conatiner-info" : {
				"& .text-legend" : {
					color      : "#2A9C5B",
					fontSize   : "14px",
					fontWeight : "normal",
					lineHeught : "18px",
				},

				"& .full-info" : {
					fontSize : "12px",

					"& .focus-data" : {
						color     : "#464E5F",
						fotWeight : "bolder",
					},
				},
			},
		},
	},

	warning : {
		height              : "100%",
		display             : "flex",
		width               : "100%",
		paddingLeft         : "13px",
		"& .body-container" : {
			display    : "flex",
			alignItems : "center",

			"& .container-icon" : {
				marginRight : "13px",
				marginLeft  : "13px",
				color       : "#C29D2F",
			},

			"& .conatiner-info" : {
				"& .text-legend" : {
					color      : "#C29D2F",
					fontSize   : "14px",
					fontWeight : "normal",
					lineHeught : "18px",
				},

				"& .full-info" : {
					fontSize : "12px",

					"& .focus-data" : {
						color     : "#464E5F",
						fotWeight : "bolder",
					},
				},
			},
		},
	},
}));


const PrimaryToast = ({icon, dataDetail, tittle, firstBody, secondBody}) => {
	const classes = useStyles();
	return (
		<div className={classes.add}>
			<div className="body-container">
				<div className="container-icon">{icon}</div>
				<div className="conatiner-info">
					<div className="text-legend">{tittle}</div>
					<div className="full-info">
						<span>{firstBody} </span>
						<span className="focus-data">{dataDetail} </span>
						<span>{secondBody}</span>
					</div>
				</div>
			</div>
		</div>
	);
};

const DangerToast = ({icon, dataDetail, tittle, firstBody, secondBody}) => {
	const classes = useStyles();
	return (
		<div className={classes.delet}>
			<div className="body-container">
				<div className="container-icon">{icon}</div>
				<div className="conatiner-info">
					<div className="text-legend">{tittle}</div>
					<div className="full-info">
						<span>{firstBody} </span>
						<span className="focus-data">{dataDetail} </span>
						<span>{secondBody}</span>
					</div>
				</div>
			</div>
		</div>
	);
};

const InfoToast = ({icon, dataDetail, tittle, firstBody, secondBody}) => {
	const classes = useStyles();
	return (
		<div className={classes.edit}>
			<div className="body-container">
				<div className="container-icon">{icon}</div>
				<div className="conatiner-info">
					<div className="text-legend">{tittle}</div>
					<div className="full-info">
						<span>{firstBody} </span>
						<span className="focus-data">{dataDetail} </span>
						<span>{secondBody}</span>
					</div>
				</div>
			</div>
		</div>
	);
};

const WarningToast = ({icon, dataDetail, tittle, firstBody, secondBody}) => {
	const classes = useStyles();
	return (
		<div className={classes.warning}>
			<div className="body-container">
				<div className="container-icon">{icon}</div>
				<div className="conatiner-info">
					<div className="text-legend">{tittle}</div>
					<div className="full-info">
						<span>{firstBody} </span>
						<span className="focus-data">{dataDetail} </span>
						<span>{secondBody}</span>
					</div>
				</div>
			</div>
		</div>
	);
};

const customToast = {
	primary      : ({icon, dataDetail, tittle, firstBody, secondBody}) => toast(<PrimaryToast {...{icon, dataDetail, tittle, firstBody, secondBody}} />, {hideProgressBar : true}),
	danger       : ({icon, dataDetail, tittle, firstBody, secondBody}) => toast(<DangerToast {...{icon, dataDetail, tittle, firstBody, secondBody}} />, {hideProgressBar : true}),
	info         : ({icon, dataDetail, tittle, firstBody, secondBody}) => toast(<InfoToast  {...{icon, dataDetail, tittle, firstBody, secondBody}} />, {hideProgressBar : true}),
	WarningToast : ({icon, dataDetail, tittle, firstBody, secondBody}) => toast(<WarningToast  {...{icon, dataDetail, tittle, firstBody, secondBody}} />, {hideProgressBar : true}),
};

export default customToast;
