import {
	OPEN_CONFIRMATION_MODAL,
	CLOSE_CONFIRMATION_MODAL,
} from "Store/constants";

const initialState = {
	open        : false,
	id          : "",
	name        : "",
	image       : "",
	roles       : "",
	title       : "",
	type        : "",
	description : "",
	dataToSend  : {},
	callback    : ()=>{},
};

const confirmationModalReducer = (state = initialState, action) => {
	switch (action.type) {
		case OPEN_CONFIRMATION_MODAL:
			return {
				...state,
				open : true,
				...action.payload,
			};
		case CLOSE_CONFIRMATION_MODAL:
			return initialState;
		default:
			return state;
	}
};

export default confirmationModalReducer;
