import classnames  from "classnames";
import AsyncSelect from "react-select/async";
// import makeAnimated from "react-select/animated";
import {
	Label,
	FormGroup,
} from "reactstrap";
import "./AsyncSelectGroup.scss";

// const animatedComponents = makeAnimated();

const AsyncSelectGroup = ({
	name = "",
	value = "",
	label = "",
	errors = {},
	customStyles = "",
	required = true,
	isClearable = true,
	handleChange = () => {},
	handleSearch = () => {},
	...rest
}) => {

	return (
		<FormGroup  id="AsyncSelectGroup">
			<Label className="form-label">
				{ `${label} ${required ? "*" : "" }` }
			</Label>
			{
				<AsyncSelect
					{...rest}
					cacheOptions
					value={value}
					defaultOptions
					onChange={handleChange}
					classNamePrefix="select"
					isClearable={isClearable}
					loadOptions={handleSearch}
					className={classnames("react-select", {
						invalidSelectInpt : errors?.[name]?.type,
						customStyles      : customStyles,
					})}
				/>

			}

			{  errors?.[name]?.type && (
				<p style={{ color : "#EA5455", fontSize : "12px", marginTop : "3px", marginBottom : "0" }}>
					{ errors?.[name]?.message }
				</p>
			)}
		</FormGroup>
	);
};
export default AsyncSelectGroup;
