import {
	UPDATE_REPORTS,
	GET_ALL_REPORTS,
	SET_REPORT_FILTERS,
	CLEAR_REPORT_FILTERS,
} from "Store/constants";


const initialState = {
	docs       : [],
	page       : 1,
	totalPages : 1,
	filter     : {
		page     : 1,
		limit    : 50,
		paid     : false,
		search   : "",
		dates    : [],
		proccess : [],
		maquila  : [],
	},
};

const reportsReducer = (state = initialState, action) => {
	switch (action.type) {
		case GET_ALL_REPORTS:
			return {
				...state,
				...action.payload,
			};
		case UPDATE_REPORTS:
			const docs = state.docs;
			const id   = action.payload.data._id;

			const newDocs = docs.map(item => {
				if (item._id === id) {
					return {
						...item,
						...action.payload.data,
					};
				}
				return item;
			});

			return {
				...state,
				docs : [...newDocs],
			};
		case SET_REPORT_FILTERS: {
			const key   = action.payload.key;
			const value = action.payload.value;

			return {
				...state,
				filter : {
					...state.filter,
					[key] : value,
				},
			};
		}
		case CLEAR_REPORT_FILTERS:
			return {
				...state,
				filter : initialState.filter,
			};
		default:
			return state;
	}
};

export default reportsReducer;
