import { combineReducers } from "redux";

// Import Own Components
import authReducer                     from "./authReducer";
import usersReducer                    from "./usersReducer";
import costsReducer                    from "./costsReducer";
import modalTutorial                   from "./modalTutorial";
import ordersReducer                   from "./ordersReducer";
import pricesReducer                   from "./pricesReducer";
import sidebarReducer                  from "./sidebarReducer";
import reportsReducer                  from "./reportsReducer";
import clientsReducer                  from "./clientsReducer";
import productReducer                  from "./productReducer";
import addressReducer                  from "./addressReducer";
import commentsReducer                 from "./commentsReducer";
import statisticsReducer               from "./statisticsReducer";
import categoriesReducer               from "./categoriesReducer";
import modalReportReducer              from "./modalReportReducer";
import modalReportProductsReducer      from "./modalReportProductsReducer";
import accessoriesReducer              from "./accessoriesReducer";
import modalDesingReducer              from "./modalDesingReducer";
import productionsReducer              from "./productionsReducer";
import makeupArtistReducer             from "./makeupArtistReducer";
import modalCategoryReducer            from "./modalCategoryReducer";
import productGroupedReducer           from "./productGroupedReducer";
import modalDowloadPdfReducer          from "./modalDowloadPdfReducer";
import modalEditMaquilaReducer         from "./modalEditMaquilaReducer";
import modalAccessoriesReducer         from "./modalAccessoriesReducer";
import confirmationModalReducer        from "./confirmationModalReducer";
import modalMakeupArtistReducer        from "./modalMakeupArtistReducer";
import navBarPendingBtnsReducer        from "./navBarPendingBtnsReducer";
import modalCheckListProductionReducer from "./modalCheckListProductionReducer";
import filesReducer                    from "./filesReducer";
import uiTable                         from "./uiTable";

export const reducers = {
	_uiTable                         : uiTable,
	_authReducer                     : authReducer,
	_filesReducer                    : filesReducer,
	_costsReducer                    : costsReducer,
	_usersReducer                    : usersReducer,
	_pricesReducer                   : pricesReducer,
	_ordersReducer                   : ordersReducer,
	_modalTutorial                   : modalTutorial,
	_sidebarReducer                  : sidebarReducer,
	_reportsReducer                  : reportsReducer,
	_clientsReducer                  : clientsReducer,
	_productsReducer                 : productReducer,
	_addressReducer                  : addressReducer,
	_commentsReducer                 : commentsReducer,
	_statisticsReducer               : statisticsReducer,
	_categoriesReducer               : categoriesReducer,
	_modalReportReducer              : modalReportReducer,
	_accessoriesReducer              : accessoriesReducer,
	_productionsReducer              : productionsReducer,
	_modalDesingReducer              : modalDesingReducer,
	_makeupArtistReducer             : makeupArtistReducer,
	_modalCategoryReducer            : modalCategoryReducer,
	_productGroupedReducer           : productGroupedReducer,
	_modalDowloadPdfReducer          : modalDowloadPdfReducer,
	_modalAccessoriesReducer         : modalAccessoriesReducer,
	_modalEditMaquilaReducer         : modalEditMaquilaReducer,
	_navBarPendingBtnsReducer        : navBarPendingBtnsReducer,
	_confirmationModalReducer        : confirmationModalReducer,
	_modalMakeupArtistReducer        : modalMakeupArtistReducer,
	_modalCheckListProductionReducer : modalCheckListProductionReducer,
	_modalReportProductsReducer      : modalReportProductsReducer,
};

const rootReducer = combineReducers(reducers);

export default rootReducer;
