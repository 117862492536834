import { useMapState }  from ".";
import { isValidArray } from "Helpers";

const useTotalSum = ({ defaultValues = { init : 0 } }) => {
	const [map, setMap, deleteKey] = useMapState(defaultValues);

	const getTotal = () => {
		const values = Object.values(map);

		if (!isValidArray(values)) return 0;

		const total = values.reduce((previousValue, currentValue) => Number(previousValue) + Number(currentValue));

		if (!total) return 0;

		return total;
	};

	return [
		setMap,
		getTotal,
		deleteKey,
	];
};

export default useTotalSum;
