const initialState = {
	docs : [],
	page : 1,
};

const ProductGroupedReducer = (state = initialState, action) => {
	switch (action.type) {
		case "GET_ALL_PRODUCTS/GROUP/CATEGORY":
			return {
				...state,
				docs : action.payload.data,
			};

		default:
			return state;
	}
};

export default ProductGroupedReducer;
