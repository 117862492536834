
import {
	GET_ALL_COSTSLISTS,
} from "Store/constants";

const initialState = {
	costs : [],
};

const costsReducer = (state = initialState, action) => {
	switch (action.type) {
		case GET_ALL_COSTSLISTS:

			return {
				...state,
				costs : action.payload.data,
			};
		default:
			return state;
	}
};

export default costsReducer;
