import {
	Spinner,
	Button,
} from "reactstrap";

const SaveButton = ({loading, text = "", ...rest}) => {
	return (
		<div className="actions">
			{
				loading ? (
					<Button.Ripple color='primary' block disabled={true}>
						<Spinner color='white' size='sm' type='grow' />
						<span className='ml-50'>Cargando...</span>
					</Button.Ripple>
				) : (
					<Button
						{...rest}
						type="submit"
						color="primary"
						variant="contained"
					>
						Guardar {text}
					</Button>
				)
			}
		</div>
	);
};
export default SaveButton;
