import {
	GET_ORDERS,
	ADD_ORDERS,
	CLEAR_ORDERS,
	REMOVE_ORDERS,
	GET_ALL_ORDERS,
	CLEAR_ORDERS_DATA,
	SET_ORDER_FILTERS,
	CLEAR_ORDER_FILTERS,
	UPDATE_ORDERS_DESING,
} from "Store/constants";

const initialState = {
	orders : [],
	order  : {},
	filter : {
		search      : "",
		client      : null,
		viewed      : false,
		remake      : false,
		filters     : [],
		inptValue   : "",
		orderColumn : {},
		limit       : 16,
		page       	: 1,
	},
	page       : 1,
	totalPages : 1,
};

const OrderReducer = (state = initialState, action) => {
	switch (action.type) {
		case GET_ORDERS:
			const date = new Date(action.payload.data.date_end);

			return {
				...state,
				order : {...action.payload.data, date_end : date},
			};
		case GET_ALL_ORDERS:
			return {
				...state,
				orders     : action.payload.data,
				page       : action.payload.page,
				totalPages : action.payload.totalPages,
			};
		case ADD_ORDERS:
			if (state.orders !== undefined) {
				const orders = [...state.orders];

				if (action.payload.data !== undefined) {
					orders.push(action.payload.data);
					return {
						...state,
						orders,
					};
				}
			}

			return state;
		case REMOVE_ORDERS:
			return {
				...state,
				orders : state.orders.filter(order => order._id !== action.payload._id),
			};
		case UPDATE_ORDERS_DESING:
			const id      = action.payload.data;
			const desings = state?.order?.desing ?? [];

			const newDesings = desings.map(desing => {
				let newDesing = { ...desing };

				if (desing._id === id) {
					newDesing = {
						...newDesing,
						status : true,
					};
				}
				if (desing.status) {
					newDesing = {
						...newDesing,
						status : false,
					};
				}
				return newDesing;
			});

			return {
				...state,
				order : {
					...state.order,
					desing : newDesings,
				},
			};
		case SET_ORDER_FILTERS: {
			const key   = action.payload.key;
			const value = action.payload.value;

			return {
				...state,
				filter : {
					...state.filter,
					[key] : value,
				},
			};
		}
		case CLEAR_ORDER_FILTERS:
			return {
				...state,
				filter : initialState.filter,
			};
		case CLEAR_ORDERS:
			return {
				...initialState,
				filter : state.filter,
			};
		case CLEAR_ORDERS_DATA:
			return {
				...initialState,
				filter : state.filter,
			};
		default:
			return state;
	}
};

export default OrderReducer;
