import {
	GET_ACCESSORIES,
	ADD_ACCESSORIES,
	UPDATE_ACCESSORIES,
	GET_ALL_ACCESSORIES,
	CLEAR_ACCESSORIES_DATA,
} from "Store/constants";

const initialState = {
	data       : [],
	info       : {},
	page       : 1,
	totalPages : 1,
};

const accessoriesReducer = (state = initialState, action) => {
	switch (action.type) {
		case GET_ACCESSORIES:
			return {
				...state,
				info : action.payload.data,
			};
		case GET_ALL_ACCESSORIES:
			return {
				...state,
				data       : action.payload.data,
				page       : action.payload.page,
				totalPages : action.payload.totalPages,
			};
		case ADD_ACCESSORIES: {
			const data = [...state.data];

			data.push(action.payload.data);
			return {
				...state,
				data,
			};
		}
		case UPDATE_ACCESSORIES: {
			const id      = action.payload._id;
			const newData = action.payload.data;

			const data = state.data.map(item => {
				if (id === item._id) {
					return {...newData};
				}
				return item;
			});

			return {
				...state,
				data,
			};
		}
		case CLEAR_ACCESSORIES_DATA: {
			return initialState;
		}
		default:
			return state;
	}
};

export default accessoriesReducer;
