import { useState } from "react";

/**
 * Custom implementation for defining maps as a state in javascript with react
 * fully reactive
 *
 * @author Alcalá León Yael Mártin A. <yael.alcalla@gmail.com>
 * @returns The map state, a function to add a key/value and a function to delete a key
 */
const useMapState = (defaultState = {}) => {
	const [mapState, setMapState] = useState(defaultState);

	const setKeyValue = (key, value) => setMapState(prevState => ({
		...prevState,
		[key] : value ?? true,
	}));

	const deleteKey = key => setMapState(prevState => {
		const newState = {...prevState};

		delete newState[key];

		return newState;
	});

	return [
		mapState,
		setKeyValue,
		deleteKey,
	];
};

export default useMapState;
