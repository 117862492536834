import {
	OPEN_MODAL_REPORT_PRODUCTS,
	CLOSE_MODAL_REPORT_PRODUCTS,
} from "Store/constants";

// Import Own Components

export const initialState = {
	isOpen : false,
	info   : {},
};

const modalReportProductsReducer = (state = initialState, action) => {
	switch (action.type) {
		case OPEN_MODAL_REPORT_PRODUCTS:
			return {
				...initialState,
				isOpen : true,
				...action.payload,
			};
		case CLOSE_MODAL_REPORT_PRODUCTS:
			return {
				...initialState,
			};
		default:
			return state;
	}
};

export default modalReportProductsReducer;
