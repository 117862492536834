import {
	OPEN_SIDEBAR,
	CLOSE_SIDEBAR,
} from "Store/constants";

// Import Own Components
import { sidebarsList } from "Components/Sidebar";

export const initialState = {
	open  : false,
	props : {},

	...Object.keys(sidebarsList).reduce((acc, sidebarName) => ({
		...acc,
		[sidebarName] : false,
	}), {}),
};

const sidebarReducer = (state = initialState, action) => {
	switch (action.type) {
		case OPEN_SIDEBAR:
			return {
				// Reset all modals
				...initialState,

				open : true,

				// Open specific modal from payload
				...action.payload,
			};
		case CLOSE_SIDEBAR:
			return {
				...initialState,
			};
		default:
			return state;
	}
};

export default sidebarReducer;
