/**
 * Parses a given date, and return the Date object, if it is an invalid
 * date, it returns undefined
 *
 * @author Alcalá León Yael Mártin A. <yael.alcalla@gmail.com>
 */
const parseDate = (date) => {
	if (!date) return;

	const newDate = new Date(date);

	return newDate instanceof Date && !isNaN(newDate)
		? newDate
		: undefined;
};

export default parseDate;
