// Auth
export const SET_USER_DATA        = "SET_USER_DATA";
export const SET_USER_EDITED      = "SET_USER_EDITED";
export const CLEAR_USER_DATA      = "CLEAR_USER_DATA";
export const  SET_USER_LENGUAGE   = " SET_USER_LENGUAGE";
export const SET_SOCIAL_LOADING   = "SET_SOCIAL_LOADING";
export const SET_SEMIDARK_THEME   = "SET_SEMIDARK_THEME";
export const CHANGE_PRIMARY_COLOR = "CHANGE_PRIMARY_COLOR";
export const SET_USER_COUNT_CONFIRMATION = "SET_USER_COUNT_CONFIRMATION";

//Users
export const ADD_USERS          = "ADD_USERS";
export const GET_USERS          = "GET_USERS";
export const REMOVE_USERS       = "REMOVE_USERS";
export const GET_USER_DATA      = "GET_USER_DATA";
export const GET_ALL_USERS      = "GET_ALL_USERS";
export const UPDATE_USERS       = "UPDATE_USERS";
export const SET_USERS_ROLE     = "SET_USERS_ROLE";
export const UPDATE_STATUS_USER = "UPDATE_STATUS_USER";
export const SET_USERS_ORDERBY  = "SET_USERS_ORDERBY";
export const SET_USERS_ORDER    = "SET_USERS_ORDER";
export const USER_FILTER_STATUS = "USER_FILTER_STATUS";
export const USER_FILTER_CLEAN  = "USER_FILTER_CLEAN";

//Clients
export const ADD_CLIENTS     = "ADD_CLIENTS";
export const GET_CLIENTS     = "GET_CLIENTS";
export const REMOVE_CLIENTS  = "REMOVE_CLIENTS";
export const GET_ALL_CLIENTS = "GET_ALL_CLIENTS";
export const UPDATE_CLIENTS  = "UPDATE_CLIENTS";

//Products
export const GET_PRODUCTS        = "GET_PRODUCTS";
export const ADD_PRODUCTS        = "ADD_PRODUCTS";
export const REMOVE_PRODUCTS     = "REMOVE_PRODUCTS";
export const UPDATE_PRODUCTS     = "UPDATE_PRODUCTS";
export const GET_ALL_PRODUCTS    = "GET_ALL_PRODUCTS";
export const CLEAR_PRODUCTS_DATA = "CLEAR_PRODUCTS_DATA";

//Categories
export const GET_CATEGORIES        = "GET_CATEGORIES";
export const ADD_CATEGORIES        = "ADD_CATEGORIES";
export const REMOVE_CATEGORIES     = "REMOVE_CATEGORIES";
export const UPDATE_CATEGORIES     = "UPDATE_CATEGORIES";
export const GET_ALL_CATEGORIES    = "GET_ALL_CATEGORIES";
export const CLEAR_CATEGORIES_DATA = "CLEAR_CATEGORIES_DATA";

//Orders
export const GET_ORDERS            = "GET_ORDERS";
export const CLEAR_ORDERS          = "CLEAR_ORDERS";
export const ADD_ORDERS            = "ADD_ORDERS";
export const REMOVE_ORDERS         = "REMOVE_ORDERS";
export const UPDATE_ORDERS         = "UPDATE_ORDERS";
export const GET_ALL_ORDERS        = "GET_ALL_ORDERS";
export const CLEAR_ORDERS_DATA     = "CLEAR_ORDERS_DATA";
export const UPDATE_ORDERS_DESING  = "UPDATE_ORDERS/DESING";
export const CLEAR_ORDER_FILTERS   = "CLEAR_ORDER_FILTERS";
export const SET_ORDER_FILTERS     = "SET_ORDER_FILTERS";


//Sidebar
export const OPEN_SIDEBAR  = "OPEN_SIDEBAR";
export const CLOSE_SIDEBAR = "CLOSE_SIDEBAR";

//Modal Category
export const OPEN_MODAL_CATEGORY  = "OPEN_MODAL_CATEGORY";
export const CLOSE_MODAL_CATEGORY = "CLOSE_MODAL_CATEGORY";

// ConfirmationModal
export const OPEN_CONFIRMATION_MODAL  = "OPEN_CONFIRMATION_MODAL";
export const CLOSE_CONFIRMATION_MODAL = "CLOSE_CONFIRMATION_MODAL";

//Productions
export const UPDATE_PRODUCTIONS        = "UPDATE_PRODUCTIONS";
export const GET_ALL_PRODUCTIONS       = "GET_ALL_PRODUCTIONS";
export const UPDATE_PRODUCTOIN_MAQUILA = "UPDATE_PRODUCTOIN_MAQUILA";

//Reports
export const UPDATE_REPORTS       = "UPDATE_REPORTS";
export const GET_ALL_REPORTS      = "GET_ALL_REPORTS";
export const SET_REPORT_FILTERS   = "SET_REPORT_FILTERS";
export const CLEAR_REPORT_FILTERS = "CLEAR_REPORT_FILTERS";

export const CLOSE_MODAL_REPORT_PRODUCTS = "CLOSE_MODAL_REPORT_PRODUCTS";
export const OPEN_MODAL_REPORT_PRODUCTS = "OPEN_MODAL_REPORT_PRODUCTS";

// Prices
export const GET_ALL_PRICELISTS = "GET_ALL_PRICELISTS";

// Modal Desing
export const SET_DESING_INFO    = "SET_DESING_INFO";
export const OPEN_MODAL_DESING  = "OPEN_MODAL_DESING";
export const CLOSE_MODAL_DESING = "CLOSE_MODAL_DESING";

// Modal dowload pdf
export const SET_DOWLOAD_PDF_INFO    = "SET_DOWLOAD_PDF_INFO";
export const OPEN_MODAL_DOWLOAD_PDF  = "OPEN_MODAL_DOWLOAD_PDF";
export const CLOSE_MODAL_DOWLOAD_PDF = "CLOSE_MODAL_DOWLOAD_PDF";

//Addresses
export const GET_ALL_ADDRESSES = "GET_ALL_ADDRESSES";
export const UPDATE_ADDRESSES  = "UPDATE_ADDRESSES";
export const GET_ADDRESSES     = "GET_ADDRESSES";

//Makeup artist
export const GET_MAKEUPARTISTS        = "GET_MAKEUPARTISTS";
export const ADD_MAKEUPARTISTS        = "ADD_MAKEUPARTISTS";
export const REMOVE_MAKEUPARTISTS     = "REMOVE_MAKEUPARTISTS";
export const UPDATE_MAKEUPARTISTS     = "UPDATE_MAKEUPARTISTS";
export const GET_ALL_MAKEUPARTISTS    = "GET_ALL_MAKEUPARTISTS";
export const CLEAR_MAKEUPARTISTS_DATA = "CLEAR_MAKEUPARTISTS_DATA";

//Modal Makeup artist
export const OPEN_MODAL_MAKEUP_ARTIST  = "OPEN_MODAL_MAKEUPARTIST";
export const CLOSE_MODAL_MAKEUP_ARTIST = "CLOSE_MODAL_MAKEUPARTIST";

//Modal Report
export const OPEN_MODAL_REPORT  = "OPEN_MODAL_REPORT";
export const CLOSE_MODAL_REPORT = "CLOSE_MODAL_REPORT";

//Accessories
export const GET_ACCESSORIES        = "GET_ACCESSORIES";
export const ADD_ACCESSORIES        = "ADD_ACCESSORIES";
export const UPDATE_ACCESSORIES     = "UPDATE_ACCESSORIES";
export const GET_ALL_ACCESSORIES    = "GET_ALL_ACCESSORIES";
export const CLEAR_ACCESSORIES_DATA = "CLEAR_ACCESSORIES_DATA";

//Modal Accessories
export const OPEN_MODAL_ACCESSORIES  = "OPEN_MODAL_ACCESSORIES";
export const CLOSE_MODAL_ACCESSORIES = "CLOSE_MODAL_ACCESSORIES";

//Modal Check-List Production
export const OPEN_MODAL_CHECKLIST_PRODUCTION  = "OPEN_MODAL_CHECKLIST_PRODUCTION";
export const CLOSE_MODAL_CHECKLIST_PRODUCTION = "CLOSE_MODAL_CHECKLIST_PRODUCTION";

//Costs List
export const GET_ALL_COSTSLISTS = "GET_ALL_COSTSLISTS";

//Comments
export const GET_COMMENTS    = "GET_COMMENTS";
export const ADD_COMMENTS    = "ADD_COMMENTS";
export const CLEAR_COMMENTS  = "CLEAR_COMMENTS";

//NavBarPendingBtns
export const SET_TOTAL_DOCS_PRE            = "SET_TOTAL_DOCS_PRE";
export const CANCEL_PENDING_FILTERS        = "CANCEL_PENDING_FILTERS";
export const FILTER_BY_PENDING_USER        = "FILTER_BY_PENDING_USER";
export const GET_ALL_ORDERS_FILTER_USER    = "GET_ORDERS/FILTER/USER";
export const FILTER_BY_PRE_PRODUCTION      = "FILTER_BY_PRE_PRODUCTION";
export const GET_ALL_ORDERS_FILTER_CLIENT  = "GET_ORDERS/FILTER/CLIENT";
export const CANCEL_PRE_PRODUCTION_FILTERS = "CANCEL_PRE_PRODUCTION_FILTERS";

export const GET_ALL_WAITING_CUSTOMER_APPROVAL   = "GET_ORDERS/COUNT/WAITING-CUSTOMER-APPROVAL";
export const FILTER_BY_WAITING_CUSTOMER_APPROVAL = "FILTER_BY_WAITING_CUSTOMER_APPROVAL";
export const CANCEL_WAITING_CUSTOMER_APPROVAL    = "CANCEL_WAITING_CUSTOMER_APPROVAL";

//Statistics
export const GET_ALL_STATISTICS = "GET_ALL_/STATISTICS/PRODUCTIONS/COUNT";

//Modal Tutorial
export const OPEN_MODAL_TUTORIAL  = "OPEN_MODAL_TUTORIAL";
export const CLOSE_MODAL_TUTORIAL = "CLOSE_MODAL_TUTORIAL";

//Edit Modal Maquila
export const OPEN_EDIT_MODAL_MAQUILA  = "OPEN_EDIT_MODAL_MAQUILA";
export const CLOSE_EDIT_MODAL_MAQUILA = "CLOSE_EDIT_MODAL_MAQUILA";

//Files
export const GET_ALL_FILES       = "GET_ALL_FILES";
export const SET_FILE_TO_REMOVE  = "SET_FILE_TO_REMOVE";
export const SET_FILES_TO_REMOVE = "SET_FILES_TO_REMOVE";
export const FILES_REMOVED       = "FILES_REMOVED";
export const CLEAR_FILES         = "CLEAR_FILES";

//Ui Table
export const START_LOADING_UI_TABLE = "START_LOADING_UI_TABLE";
export const CANDEL_LOADING_UI_TABLE = "CANDEL_LOADING_UI_TABLE";
