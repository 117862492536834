import { Button }         from "reactstrap";
import classnames         from "classnames";
import { X }              from "react-feather";
import { useTranslation } from "react-i18next";

import "Translations/i18n";
import "./DropZoneImg.scss";

const DropZoneImg = ({
	delegations : {
		classNm,
		callback,
		imageUrl,
		setImage,
		defaultImage,
		getRootProps,
		customErrors,
		getInputProps,
		backgroundSize,
	},
}) => {
	const { t } = useTranslation();
	return (
		<div id="DropZoneImg">
			<div
				className={classnames(classNm, {
					invalid : customErrors?.image,
				})}
				style={
					{
						backgroundSize,
						backgroundPosition : "center",
						backgroundRepeat   : "no-repeat",
						backgroundImage    : `url(${imageUrl})`,
					}
				}
				{...getRootProps()}
			>
				<input {...getInputProps()} />
				<Button.Ripple
					color="danger"
					type="button"
					className={classnames("removeImage-button", {
						show : imageUrl,
					})}
					onClick={e => {
						e.stopPropagation();
						setImage(null);
						callback();
					}}
				>
					<X size={14} />
				</Button.Ripple>
				{ imageUrl ? (
					<img src={imageUrl} hidden />
				) : (
					<>
						<div
							className="backGroundDropZone"
							style={{
								backgroundImage : `url(${defaultImage})`,
							}}
						>
							{
								!defaultImage ? (
									<>
										{ t("drag_click_new_file") }<br />
										<span className="files">(.jpg, .png)</span>
									</>
								) :
									(
										<div>&nbsp;</div>
									)
							}
						</div>
					</>
				) }
			</div>
		</div>
	);
};
export default DropZoneImg;
