export const TRANSLATIONS_ES = {
	unseen                : "No visto",
	count                 : "cuenta",
	clean_filter          : "Limpiar Filtro",
	filter                : "Filtrar",
	seen                  : "Visto",
	new                   : "Nuevo",
	new_plural            : "Nuevos",
	notifications         : "Notificaciones",
	empty                 : "Vacío",
	preorder              : "Ordenes",
	lenguage              : "Idioma",
	role                  : "Rol",
	"0"                   : "Inactivo",
	"1"                   : "Activo",
	operator              : "Operador",
	designer              : "Diseñador",
	supervisor            : "Supervisor",
	priorities            : "Prioridades",
	administrator         : "Admin",
	loading               : "Cargando",
	back                  : "Ant.",
	next                  : "Sig.",
	date_format           : "{{date, DD MMMM, YYYY}}",
	date_format_number    : "DD/MM/YYYY hh:mm A",
	date_format_value     : "DD/MM/YYYY",
	date_format_flatpickr : "d/m/Y",
	logout                : "Cerrar sesión",
	home                  : "Inicio",
	cancel                : "Cancelar",
	orders                : "Ordenes",
	line                  : "Linea",
	quantity              : "Cantidad",
	figure                : "Figura",
	measure               : "Medida",
	date_delivery         : "Fecha de entrega estimada",
	engraved_behind       : "Grabado por Detrás",
	letters_behind        : "Letras por Detrás",
	specs                 : "Especificaciones",
	stone_color           : "Color de piedra",
	material              : "Material",
	download              : "Descargar",
	remove                : "Eliminar",
	save_pdf              : "Guardar PDF",
	pending               : "Diseño",
	in_progress           : "En progreso",
	completed             : "Completado",
	rejected              : "Rechazado",
	process               : "En proceso",
	approved              : "Aprobado",
	desings               : "Diseños",
	files                 : "Archivos",
	drag_click_new_file   : "Arrastra o da click para agregar un archivo",
	notify_client         : "Deseo notificar al cliente.",
	last_movements        : "Últimos Movimientos",
	folio                 : "Folio",
	status                : "Estado",
	priority              : "Prioridad",
	approval              : "Aprobación",
	actions               : "Acciones",
	products              : "Productos",
	pieces                : "Piezas",
	price                 : "Precio",
	image_optional        : "Imagen opcional",
	specs_optional        : "Especificaciones opcionales",
	figure_optional       : "Figura opcional",
	optional              : "Opcional",
	critical              : "Crítica",
	low                  	: "Baja",
	medium               	: "Media",
	recover_password      : {
		title     : "¿Olvidaste tu Contraseña?",
		body      : "Ingresa tu correo electrónico y te enviaremos instrucciones para restablecer tu contraseña.",
		label     : "Correo Electrónico",
		label_btn : "Enviar",
		back      : "Regresar",
	},
	comment : {
		comment      : "Comentar",
		comments     : "Comentarios",
		comment_sing : "Comentario",
		add          : "Añadir un comentario",
		no_comments  : "Sin Comentrios",
	},
	address : {
		address_shipping    : "Dirección de envío",
		address_facturation : "Dirección de facturación",
	},
	order : {
		check_wca              : "Marcar como Esperar por la aprobación del cliente",
		order_name_label       : "Nombre de la orden",
		order_name_placeholder : "Ingrese el nombre de la orden",
		viwed                  : "Visto",
		show                   : "Mostrar todo el formulario",
		label_pending_filter   : "Filtrando por pendientes",
		label_wca_filter       : "Filtrando por Esperando Aprobación del Cliente",
		fillForm               : "Llenar el formulario",
		dowloandOrder          : "Archivos de la orden",
		in_review              : "En revisión",
		accepted               : "Aceptadas",
		add_order              : "Agregar Orden",
		edit_order             : "Editar Orden",
		order                  : "Orden",
		order_detail           : "Detalle Orden",
		pending                : "En diseño",
		in_progress            : "En producción",
		completed              : "Completado",
		total_pieces           : "Piezas totales",
		amount                 : "Importe",
		save_order             : "Guardar Orden",

		history : {
			// design_approved : "La orden ha sido aprovada",
			edited          : "La orden ha sido editada",
			created         : "La orden ha sido creada",
			new_layout      : "Un nuevo diseño ha sido agregado",
			design_approved : "El diseño ha sido aprobado",
			design_pending  : "El diseño ha sido cambiado a pendiente",
		},

		alert : {
			title : "Permisos Insuficientes",
			msg   : "La orden no puede ser editada, ya que fue aceptada por un trabajador.",
		},
	},
	desing : {
		submit_new_desing   : "Enviar nuevo diseño",
		update_status_title : "Actualizar Estado del Diseño",
		update_status_msg   : "¿Seguro que quieres cambiar el estado actual del diseño?",
	},
	client : {
		client      : "Cliente",
		info_client : "Información del cliente",
	},
	product : {
		product     : "Producto",
		piece       : "Pieza",
		add_product : "Agregar producto",
	},
	dropZone : {
		drag_file_here    : "Arrastra un máximo de {{maxFiles}} archivos adicionales.",
		attach            : "Adjuntar archivos",
		title_error      	: "Ha ocurrido un error.",
		body_error       	: "Ocurrió un error al intentar subir los archivos,  favor de verificar que la cantidad seleccionada de archivos no supere el limite.",
		limit_title_error : "Límite de archivos excedido",
		limit_body_error  : "Superaste el limite permitido para subir archivos, favor de subir un máximo de {{maxFiles}} archivos.",
	},
	placeholder : {
		search               : "Buscar",
		select               : "Seleccionar",
		enter_address        : "Ingresa la dirección",
		select_date_delivery : "Seleccione la fecha de entrega",
		select_product       : "Seleccione un producto",
		select_client        : "Seleccione un cliente",
		enter_price_product  : "Ingresa el precio del producto",
		enter_quantity       : "Ingresa la cantidad",
		max_characters       : "Máximo de caracteres",
		select_figure        : "Seleccione una figura",
		write_letters        : "Escribe las letras",
		select_measure       : "Seleccione una medida",
		select_stone_color   : "Seleccione un color de piedra",
		select_material      : "Seleccione un material",
		enter_price          : "Ingresa el precio del producto",
	},
	feedBack : {
		email            : "Ingresa un correo electrónico válido",
		file             : "Debes subir un archivo",
		not_empty        : "Este campo no puede estar vacío.",
		quantity         : "Debes ingresar una cantidad y la cantidad no puede ser menor a 1.",
		address          : "Debes ingresar una dirección.",
		address_shipping : "Debes ingresar una dirección de envío.",
		date_delivery    : "Debes ingresar una fecha de entrega.",
		product          : "Debes seleccionar un producto.",
		client           : "Debes seleccionar un cliente.",
		price            : "Debes ingresar un precio.",
		measure          : "Debes seleccionar una medida.",
		stone_color      : "Debes seleccionar un color de piedra.",
		material         : "Debes seleccionar un material.",
		figure           : "Debes seleccionar una figura.",
		letters          : "Debes ingresar las letras.",
	},
	modals : {
		delete_order : {
			question    : "¿Seguro que quieres eliminar la orden",
			description : "Eliminaremos permanentemente la orden. Esta acción no es reversible.",
		},
	},
	roles : {
		administrator  : "Administrador",
		supervisor     : "Supervisor",
		operator       : "Operador",
		designer       : "Diseñador",
		capturer       : "Capturador",
		client         : "Cliente",
		administration : "Administrativo",

	},
	login_client : {
		welcome           : "¡Bienvenido de Nuevo!",
		msg_access        : "<p>Ingresa tus credenciales de usuario para <br />acceder a la plataforma.</p>",
		email             : "Correo Electrónico",
		email_placeholder : "Ingresa tu correo electrónico",
		password          : "Contraseña",
		recover_password  : "¿Olvidaste la contraseña?",
		loading           : "Cargando",
		login             : "Iniciar sesión",
		inprodi_msg       : "Desarrollado con ❤️ por Inprodi Web Studio.",
		lan               : "Language",
	},
};
