import {
	GET_PRODUCTS,
	ADD_PRODUCTS,
	REMOVE_PRODUCTS,
	UPDATE_PRODUCTS,
	GET_ALL_PRODUCTS,
	CLEAR_PRODUCTS_DATA,
} from "Store/constants";

const initialState = {
	products   : [],
	product    : {},
	page       : 1,
	totalPages : 1,
};

const ProductReducer = (state = initialState, action) => {
	switch (action.type) {
		case GET_PRODUCTS:
			return {
				...state,
				product : action.payload.data,
			};
		case GET_ALL_PRODUCTS:
			return {
				...state,
				products   : action.payload.data,
				page       : action.payload.page,
				totalPages : action.payload.totalPages,
			};
		case ADD_PRODUCTS:
			const products = [...state.products];
			products.push(action.payload.data);
			return {
				...state,
				products,
			};
		case REMOVE_PRODUCTS:
			return {
				...state,
				products : state.products.filter(product => product._id !== action.payload._id),
			};
		case UPDATE_PRODUCTS:
			const indexProduct = state.products.findIndex(product => product._id  === action.payload._id);

			if (indexProduct === -1) return {...state};

			const newProducts = [...state.products];

			newProducts[indexProduct] = { ...action.payload.data };

			return {
				...state,
				products : newProducts,
			};
		case CLEAR_PRODUCTS_DATA:
			return initialState;
		default:
			return state;
	}
};

export default ProductReducer;
