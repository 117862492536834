import i18n                 from "i18next";
import { initReactI18next } from "react-i18next";
import LanguageDetector     from "i18next-browser-languagedetector";

import { TRANSLATIONS_ES } from "./es/translations";
import { TRANSLATIONS_EN } from "./en/translations";
import moment              from "moment";


i18n
	.use(LanguageDetector)
	.use(initReactI18next)
	.init({
		interpolation : {
			format : function(value, format, lng) {
				moment.locale(lng);
				return moment(value).format(format);
			},
		},
		resources : {
			en : {
				translation : TRANSLATIONS_EN,
			},
			es : {
				translation : TRANSLATIONS_ES,
			},
		},
	});
