import {
	OPEN_MODAL_CHECKLIST_PRODUCTION,
	CLOSE_MODAL_CHECKLIST_PRODUCTION,
} from "Store/constants";

export const initialState = {
	isOpen : false,
	data   : {},
};

const modalCheckListProduction = (state = initialState, action) => {
	switch (action.type) {
		case OPEN_MODAL_CHECKLIST_PRODUCTION:
			return {
				...initialState,
				isOpen : true,
				...action.payload,
			};
		case CLOSE_MODAL_CHECKLIST_PRODUCTION:
			return {
				...initialState,
			};
		default:
			return state;
	}
};

export default modalCheckListProduction;
