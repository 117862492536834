import { connect } from "react-redux";
import {
	Spinner,
	Button,
	Form,
	FormGroup,
	Label,
	InputGroup,
	CustomInput,
	FormFeedback,
} from "reactstrap";

// Import Own Components
import { bindAll }    from "Helpers";
import Input          from "Components/Input";
import DropZoneImg    from "Components/DropZoneImg";
import FlatPickrGroup from "Components/FlatPickrGroup";
import FormGroupInput from "Components/FormGroupInput";
import SidebarActions from "Store/Actions/SidebarActions";
import avatar         from "Resources/images/avatars/avatar.png";
import SideBarHeader  from "../SideBarHeader";
import "@styles/react/libs/flatpickr/flatpickr.scss";


const UserSidebar = ({
	sidebarActions,
	delegations : {
		turn,
		errors,
		editing,
		setValue,
		password,
		filesImg,
		register,
		getValues,
		changeTurn,
		clearErrors,
		setFilesImg,
		chargeButton,
		handleSubmitForm,
	},
}) => (
	<div>
		<SideBarHeader
			editing={false}
			sideBarName="Usuraio"
			sidebarActions={sidebarActions}
		/>

		<Form onSubmit={handleSubmitForm}>
			<DropZoneImg
				image={filesImg}
				defaultImage={avatar}
				setImage={setFilesImg}
				classNm="userImageContainer mt-1"
			/>

			<FormGroupInput
				id="register-name"
				type="text"
				name="name"
				label="Nombre(s)"
				style={{ textTransform : "capitalize" }}
				errors={errors}
				register={register}
				placeholder="Ingresa el nombre"
			/>
			<FormGroupInput
				id="register-lastname"
				type="text"
				name="lastname"
				label="Apellidos"
				style={{ textTransform : "capitalize" }}
				errors={errors}
				register={register}
				placeholder="Ingresa los apellidos"
			/>
			<FormGroupInput
				id="register-username"
				type="text"
				name="username"
				label="Nombre de usuario"
				errors={errors}
				register={register}
				placeholder="Ingresa un alias"
			/>

			<FormGroup>
				<Label className="form-label">
					Rol*
				</Label>
				<div className="radio-btns-wrapper mb-1">
					<div className="d-flex">
						<CustomInput
							onChange={(e) => {
								setValue("role", e.target.value, {required : true});
								clearErrors("role");
							}}
							checked={getValues("role") === "administrator"}
							invalid={!!errors?.role?.type}
							value="administrator"
							name='role'
							id="administrator"
							type="radio"
						/>
						<Label for="administrator">Administrador</Label>
					</div>
					<div className="d-flex">
						<CustomInput
							onChange={(e) => {
								setValue("role", e.target.value, {required : true});
								clearErrors("role");
							}}
							checked={getValues("role") === "supervisor"}
							invalid={!!errors?.role?.type}
							value={"supervisor"}
							name='role'
							id="supervisor"
							type="radio"
						/>
						<Label for="supervisor">Supervisor</Label>
					</div>
					<div className="d-flex">
						<CustomInput
							onChange={(e) => {
								setValue("role", e.target.value, {required : true});
								clearErrors("role");
							}}
							checked={getValues("role") === "operator"}
							invalid={!!errors?.role?.type}
							value={"operator"}
							name='role'
							id="operator"
							type="radio"
						/>
						<Label for="operator">Operador</Label>
					</div>
					<div className="d-flex">
						<CustomInput
							onChange={(e) => {
								setValue("role", e.target.value, {required : true});
								clearErrors("role");
							}}
							checked={getValues("role") === "designer"}
							invalid={!!errors?.role?.type}
							value={"designer"}
							name='role'
							id="designer"
							type="radio"
						/>
						<Label for="designer">Diseñador</Label>
					</div>
					<div className="d-flex">
						<CustomInput
							onChange={(e) => {
								setValue("role", e.target.value, {required : true});
								clearErrors("role");
							}}
							checked={getValues("role") === "administration"}
							invalid={!!errors?.role?.type}
							value={"administration"}
							name='role'
							id="administration"
							type="radio"
						/>
						<Label for="administration">Administrativo</Label>
					</div>
				</div>

				{
					errors?.role?.type && (
						<p style={{color : "#ea5455", fontSize : "12px"}}>Campo requerido</p>
					)
				}
			</FormGroup>

			<FormGroup className="d-flex">
				<div style={{width : "100%", marginRight : "5px", marginLeft : "5px"}}>
					<FlatPickrGroup
						value={turn.check}
						error="dateBillError"
						label="Entrada"
						handleChange={(selectedDates, dateStr, instance) => {
							changeTurn("check", dateStr);
						}}
						options={{
							enableTime : true,
							noCalendar : true,
							dateFormat : "h:i K",
						}}
						placeholder="00:00"
					/>
				</div>
				<div style={{width : "100%", marginRight : "5px", marginLeft : "5px"}}>
					<FlatPickrGroup
						value={turn.breakfast}
						error="dateBillError"
						label="Desayuno"
						handleChange={(selectedDates, dateStr, instance) => {
							changeTurn("breakfast", dateStr);
						}}
						options={{
							enableTime : true,
							noCalendar : true,
							dateFormat : "h:i K",
						}}
						placeholder="00:00"
					/>
				</div>
				<div style={{width : "100%", marginRight : "5px", marginLeft : "5px"}}>
					<FlatPickrGroup
						value={turn.mealtime}
						error="dateBillError"
						label="Comida"
						handleChange={(selectedDates, dateStr, instance) => {
							changeTurn("mealtime", dateStr);
						}}
						options={{
							enableTime : true,
							noCalendar : true,
							dateFormat : "h:i K",
						}}
						placeholder="00:00"
					/>
				</div>
				<div style={{width : "100%"}}>
					<FlatPickrGroup
						value={turn.departure}
						error="dateBillError"
						label="Salida"
						handleChange={(selectedDates, dateStr, instance) => {
							changeTurn("departure", dateStr);
						}}
						options={{
							enableTime : true,
							noCalendar : true,
							dateFormat : "h:i K",
						}}
						placeholder="00:00"
					/>
				</div>
			</FormGroup>
			<FormGroup>
				<Label className="form-label" for="password">
					Contraseña*
				</Label>
				<InputGroup className="formInput">
					<Input
						type="password"
						id="password"
						placeholder="Ingresa una contraseña"
						name="password"
						invalid={!!errors?.password?.type}
						{...register("password", { required : !editing, minLength : 8 })}
					/>
					{ errors?.password?.type === "required" && (
						<FormFeedback>
							Este campo no puede estar vacío
						</FormFeedback>
					) }
					{ errors?.password?.type === "minLength" && (
						<FormFeedback>
							La contraseña debe tener al menos 8 caracteres
						</FormFeedback>
					) }
				</InputGroup>
			</FormGroup>
			<FormGroup>
				<Label className="form-label" for="register-password">
					Confirmar Contraseña*
				</Label>
				<InputGroup className="formInput">
					<Input
						type="password"
						id="register-password2"
						placeholder="Ingresa una contraseña"
						name="password2"
						invalid={!!errors?.password2?.type}
						{...register("password2", {
							required : !editing,
							validate : value =>
								value === password.current || "The passwords do not match"})}
					/>
					{ errors?.password?.type === "required" && (
						<FormFeedback>
							Este campo no puede estar vacío
						</FormFeedback>
					) }
					{ errors?.password2?.type === "validate" && (
						<FormFeedback>
							Las contraseñas no coinciden
						</FormFeedback>
					) }
				</InputGroup>
			</FormGroup>
			<FormGroupInput
				id="register-lastname"
				type="textarea"
				name="notes"
				label="Notas Generales"
				errors={undefined}
				register={register}
				placeholder="Puedes ingresar una nota..."
				options={{required : false}}
			/>
			<div className="actions">
				{
					chargeButton ? (
						<Button.Ripple color='primary' block disabled={true}>
							<Spinner color='white' size='sm' type='grow' />
							<span className='ml-50'>Cargando...</span>
						</Button.Ripple>
					) : (
						<Button
							type="submit"
							variant="contained"
							color="primary"
						>
							Guardar Usuario
						</Button>
					)
				}
			</div>
		</Form>
	</div>
);

const mapDispatchToProps = bindAll({ SidebarActions });

export default connect(null, mapDispatchToProps)(UserSidebar);
