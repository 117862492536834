
import {
	GET_ALL_PRICELISTS,
} from "Store/constants";

const initialState = {
	prices : [],
};

const pricesReducer = (state = initialState, action) => {
	switch (action.type) {
		case GET_ALL_PRICELISTS:
			const newPrices = action.payload.data.map(price =>
				({
					id_price_list : price._id,
					name          : price.name,
				})
			);
			return {
				...state,
				prices : newPrices,
			};
		default:
			return state;
	}
};

export default pricesReducer;
