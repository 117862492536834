import {
	GET_ALL_FILES,
	SET_FILE_TO_REMOVE,
	SET_FILES_TO_REMOVE,
	FILES_REMOVED,
	CLEAR_FILES,
} from "Store/constants";

const initialState = {
	filesToRemoved : [],
};

const filesReducer = (state = initialState, action) => {
	switch (action.type) {
		case GET_ALL_FILES:
			return {
				...state,
				...action.payload,
			};
		case SET_FILE_TO_REMOVE:
			return {
				...state,
				filesToRemoved : [
					...state.filesToRemoved,
					action.payload.data,
				],
			};
		case SET_FILES_TO_REMOVE:
			return {
				...state,
				filesToRemoved : [
					...state.filesToRemoved,
					...action.payload.data,
				],
			};
		case FILES_REMOVED:
			return {
				...state,
				filesToRemoved : [],
			};
		case CLEAR_FILES:
			return initialState;
		default:
			return state;
	}
};

export default filesReducer;
