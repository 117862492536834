
import {
	GET_ALL_STATISTICS,
} from "Store/constants";

const initialState = {
	info : {},
};

const statisticsReducer = (state = initialState, action) => {
	switch (action.type) {
		case GET_ALL_STATISTICS:
			const data = action.payload.data;
			delete data?.[0]?.["_id"];

			return {
				...state,
				info : data,
			};
		default:
			return state;
	}
};

export default statisticsReducer;
