import { useState, useCallback } from "react";

/**
 * Defines general boolean logic as react states
 *
 * @author Alcalá León Yael Mártin A. <yael.alcalla@gmail.com>
 */
const useBooleanLogic = (defaultValue = false) => {
	const [open, setOpen] = useState(defaultValue);

	const handleOpen  = useCallback(() => setOpen(true), []);
	const handleClose = useCallback(() => setOpen(false), []);

	const toggle = useCallback(() => setOpen(value => !value), []);

	return {
		open,
		toggle,
		setOpen,
		handleOpen,
		handleClose,
	};
};

export default useBooleanLogic;
